<template>
  <div>
    <FormWrapper
      :formData="dataObject"
      :id="id"
      class="px-12 pt-8 pb-12 bg-white rounded"
    />
  </div>
</template>

<script>
import FormWrapper from "../../forms/FormWrapper";

export default {
  name: "UpdateFacilityDetails",

  components: {
    FormWrapper,
  },

  props: {
    dataObject: {
      required: false,
      type: Object,
    },
    id: {
      required: false,
      type: String,
    },
  },
};
</script>
