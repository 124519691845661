<template>
  <div class="flex">
    <h2>Search results</h2>
    <h2 v-show="notEmpty" class="pl-3">
      for
      <span v-for="(value, i) in data" :key="i">
        <em>{{ value + " " }}</em>
      </span>
    </h2>
  </div>
</template>
<script>
export default {
  name: "DynamicHeading",
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: false,
    },
  },
  computed: {
    notEmpty() {
      return this.data.country > 0 || this.data.taxa > 0;
    },
  },
};
</script>
