<template>
  <div
    class="relative w-80 h-56 bg-cover mr-8 text-white rounded"
    :style="backgroundImage"
  >
    <h3 class="mt-7 ml-7">
      {{ title }}
    </h3>
    <button
      @click="buttonClick"
      class="absolute text-white bg-blue w-28 h-14 bottom-7 left-7"
    >
      {{ buttonText }}
    </button>
  </div>
</template>

<script>
import Turbolinks from "turbolinks";

export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    image: {
      required: false,
      type: String,
    },
    buttonText: {
      required: true,
      type: String,
    },
    forumCategoryUrl: {
      required: true,
      type: String,
    },
  },

  computed: {
    backgroundImage() {
      return this.image
        ? `backgroundImage: url('${this.image}')`
        : "backgroundColor: #9ebc6e";
    },
  },
  methods: {
    buttonClick() {
      return Turbolinks.visit(`${this.forumCategoryUrl}`);
    }
  }
};
</script>
