<template>
  <button>
    <RightArrow class="row-span-2" :class="classesButton" />
  </button>
</template>
<script>
import RightArrow from "../icons/RightArrow";
export default {
  name: "RoundButtonNoRedirect",
  components: { RightArrow },
  props: {
    height: {
      type: String,
      required: false,
      default: "h-9",
    },
    width: {
      type: String,
      required: false,
      default: "w-9",
    },
  },

  computed: {
    classesButton() {
      return `${this.height} ${this.width}`;
    },
  },
};
</script>
