<template>
  <div class="z-100 cursor-pointer">
    <a class="flex items-center" @click="optionsDropdown">
      <span
        class="
          bg-green
          inline-block
          w-12
          h-12
          rounded-full
          text-white
          leading-12
          text-center
        "
      >
        <strong>{{ initials }}</strong>
      </span>
      <ArrowDown class="w-3 ml-2" />
    </a>
    <ul class="bubble absolute z-50 right-24 mt-3 p-4 bg-white" v-show="isOpen">
      <li class="p-2 px-6 hover:bg-green-light" v-html="myAccount" />
      <li class="p-2 px-6 hover:bg-green-light" v-html="logout" />
    </ul>
  </div>
</template>

<script>
import ArrowDown from '../icons/ArrowDown.vue';

export default {
  name: "NavPopup",
  components: {
    ArrowDown,
  },
  props: {
    myAccount: {
      type: String,
      required: true,
    },
    logout: {
      type: String,
      required: true,
    },
    userName: {
      type: String,
      required: true,
      default: "m e",
    },
  },

  data() {
    return {
      isOpen: false,
      itemsArray: [],
    };
  },

  computed: {
    // stolen from https://stackoverflow.com/questions/33076177/getting-name-initials-using-js
    initials() {
      let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
      let initials = [...this.userName.matchAll(rgx)] || [];
      return (initials = (
        (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
      ).toUpperCase());
    },
  },

  mounted() {
    this.populateItems();
  },

  methods: {
    optionsDropdown() {
      this.isOpen ? (this.isOpen = false) : (this.isOpen = true);
    },
    populateItems() {
      this.itemsArray.push(this.myAccount, this.logout);
    },
  },
};
</script>
