<template>
  <div class="px-10 py-14">
    <h2 v-if="id === 'decision-1'">{{ text.title.decisions }}</h2>
    <h2 v-else class="mb-12">{{ text.title.srg }}</h2>
    <div
      v-if="dataArray[0] && dataArray[0].user_name"
      class="text-grey text-sm mb-3"
    >
      <span>{{ text.added_by }}&nbsp;</span>
      {{ dataArray[0].user_name || "" }}
      <span class="px-1 mb-3">
      {{ dataArray[0].created_at }}
      </span>
    </div>
    <div
      v-for="(item, itemIndex) in activeDecisions"
      :key="itemIndex"
      class="pb-6 divide-y-4 divide-blue divide-dashed mb-4"
    >
      <FacilityDecision :id="id" :item="item" :text="text" />
    </div>
    <div>
      <button
        v-if="historicalDecisions.length > 0 && activeDecisions.length > 0"
        class="flex leading-tight"
        @click="toggleShowHistoricData"
      >
        <div class="text-blue underline font-medium">
          {{ showHistoricData ? "Hide historic data" : "Show historic data" }}
        </div>
        <div
          class="
            rounded-full
            border border-blue
            text-blue
            bg-white
            font-thin
            h-6
            w-6
            ml-2
            font-medium
          "
        >
          <p
            v-if="showHistoricData"
            class="flex items-center justify-center font-medium"
          >
            &#8722;
          </p>
          <p v-else>&#43;</p>
        </div>
      </button>
    </div>
    <div v-if="showHistoricData || activeDecisions.length == 0" class="mt-12">
      <div
        v-for="(item, itemIndex) in historicalDecisions"
        :key="itemIndex"
        class="pb-6 divide-y-4 divide-blue divide-dashed mb-4"
      >
        <FacilityDecision :id="id" :item="item" :text="text" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import { setAxiosHeaders } from "../../../helpers/axios";
import FacilityDecision from "./FacilityDecision.vue";

export default {
  name: "DecisionModal",
  components: {
    FacilityDecision,
  },
  props: {
    dataArray: {
      required: true,
      type: Array,
    },
    text: {
      required: true,
      type: Object,
    },
    id: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      showHistoricData: false,
    };
  },

  computed: {
    activeDecisions() {
      return this.dataArray.filter((el) => el.is_active || el.is_current)
    },
    historicalDecisions() {
      return this.dataArray.filter((el) => el.is_active === false || el.is_current === false)
    }
  },

  mounted() {
    setAxiosHeaders(axios);
  },

  methods: {
    unixToHumanDate(timestamp) {
      return moment.unix(timestamp).format("DD/MM/YYYY");
    },
    closeModal() {
      this.$root.$emit("close:modal", `modal-${this.id}`);
    },
    toggleShowHistoricData() {
      this.showHistoricData = !this.showHistoricData;
    },
  },
};
</script>
