<template>
  <div class="px-site min-h-96 bg-lilac-light shadow-md">
    <div class="pt-20">
      <div>
        <h2 class="mb-3">
          {{ facilityDetails.full_name }}
        </h2>
        <div class="flex">
          <p>{{ country.name }}</p>
          <div class="mx-2">•</div>
          <div class="flex">
            <Modal
              id="facility details"
              textColor="text-grey-dark"
              textSize="text-base"
              :localeModal="localeModal"
              :facilityDetails="facilityDetails"
              :updatedAt="updatedAt"
            />
            <div class="mx-2">•</div>
            <Modal
              id="update"
              textColor="text-blue"
              textSize="text-base"
              :facilityDetails="facilityDetails"
              :url="`/facilities/${this.facilityDetails.id}/edit.json`"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="flex gap-x-20 mt-20 mb-10">
      <DecisionBlock
        id="decision-1"
        decisionType="facility"
        :localeModal="localeModal"
        :url="facilityLevelDecisionsUrl"
        :hasDecisions="hasFacilityLevelDecisions"
        :activeDecision="activeDecision"
        :labels="labels"
      />
      <DecisionBlock
        id="decision-2"
        decisionType="srg"
        :localeModal="localeModal"
        :url="srgDecisionsUrl"
        :hasDecisions="hasSrgDecisions"
        :activeDecision="srgActiveDecision"
        :labels="labels"
      />
      <div>
        <p class="font-medium mb-3">{{ labels.heading_3.label }}</p>
        <div class="flex">
          <div>
            {{ lastApplicationDate ? unixToHumanDate(lastApplicationDate) : 'No applications yet' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "./modals/Modal.vue";
import DecisionBlock from "../decisionComponents/DecisionBlock"
import moment from "moment";
import { Endpoints } from "../../constants/constants.js";
import axios from 'axios';

export default {
  name: "FacilityInfo",
  
  components: {
    Modal,
    DecisionBlock
  },

  props: {
    facilityDetails: {
      type: Object,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    country: {
      type: Object,
      required: true,
    },
    labels: {
      type: Object,
      required: true,
    },
    localeModal: {
      type: Object,
      required: true,
    },
    lastApplicationDate: {
      type: Number,
      required: false,
    },
    updatedAt: {
      type: Number,
      required: false,
    },
    facilityLevelDecisionsCount: {
      type: Number,
      required: false,
    },
    facilityLevelDecisionsUrl: {
      type: String,
      required: false,
    },
    activeDecision: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      decisions: [],
      speciesIds: []
    }
  },

  computed: {
    srgDecisionsUrl() {
      this.data.forEach(species => this.speciesIds.push(`${Endpoints.SRG_PARAM_TAXON + '=' + species.species_plus_id + '&'}`))
      return Endpoints.SRG_URL + '?' + this.speciesIds.join('') + Endpoints.SRG_PARAM_COUNTRY + '=' + this.country.species_plus_id || []
    },
    srgActiveDecisions() {
      //filter decisions if any is_current return true
      return this.decisions.filter(d => d.is_current === true)
    },
    srgActiveDecision() {
      return this.srgActiveDecisions.length > 0
    },
    hasSrgDecisions() {
      return this.decisions.length > 0
    },
    hasFacilityLevelDecisions() {
      return this.facilityLevelDecisionsCount > 0
    }
  },

  mounted() {
    this.getData()
  },

  methods: {
    unixToHumanDate(timestamp) {
      return moment.unix(timestamp).format("DD/MM/YYYY")
    },

    setDecisions() {
      this.$root.$emit('current-decision', this.srgActiveDecisions)
    },

   getData() {
      axios.get(this.srgDecisionsUrl)
      .then((response) => {
        this.decisions = response.data
        this.setDecisions()
      })
      .catch(function (error) {
        console.log(error)
      })
    }
  },
}
</script>
