<template>
  <div class="custom-select">
    <label v-if="selectLabel" for="select">{{ selectLabel }}</label>
    <select
      class="form-select bg-white p-4 pr-8 bg-white border rounded block w-full"
      @change="change($event)"
    >
      <option
        class="bg-white p-4 hover:bg-lilac position-bg"
        v-for="(option, i) in options"
        :key="i"
        :value="option"
      >
        {{ option }}
      </option>
    </select>
  </div>
</template>
<script>
export default {
  name: "DropdownFilter",

  props: {
    options: {
      type: Array,
      required: true
    },
    selectLabel: {
      type: String,
      required: false
    }
  },
  // send the default option on pageload
  mounted() {
    this.updateSelectedValue(this.options[0])
  },
  methods: {
    change (event) {
      const selectedOption = event.target.value

      this.updateSelectedValue(selectedOption)
    },
    updateSelectedValue(option) {
      const kebabValue = option.toLowerCase().replace(" ", "-")

      this.$emit("selected", kebabValue)
    }
  }
}
</script>
