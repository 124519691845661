<template>
  <input
    class="p-3 border rounded"
    v-model="input"
    @change.prevent="change"
    :placeholder="placeholder"
    :type="type"
    :value="value"
  />
</template>
<script>
export default {
  name: 'FieldInput',
  props: {
    inTab: {
      type: Boolean,
      required: false,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: true,
    },
    value: {
      required: true,
    },
  },
  data() {
    return {
      input: this.value,
    };
  },
  created() {
    this.$emit('update', {
      name: this.name,
      input: this.input || this.value,
      inTab: this.inTab,
    });
  },
  methods: {
    change() {
      this.$emit('update', {
        name: this.name,
        input: this.input || this.value,
        inTab: this.inTab,
      });
    },
  },
};
</script>
