<template>
  <div>
    <div class="mb-6 divide-y divide-gray-400">
      <div v-if="id === 'decision-1'" class="grid grid-cols-2">
        <div class="font-medium">{{ text.assessment }}</div>
        <div>{{ assessmentText }}</div>
      </div>
      <div v-else class="grid grid-cols-2">
        <div class="font-medium">{{ text.type }}</div>
        <div>{{ item.eu_decision_type.name }}</div>
      </div>
      <div v-if="id === 'decision-1'" class="grid grid-cols-2">
        <div class="font-medium">{{ text.facility }}</div>
        <div>{{ item.facility_name }}</div>
      </div>
      <div class="grid grid-cols-2">
        <div class="font-medium">{{ text.species }}</div>
        <div class="italic" v-if="id === 'decision-1'">{{ item.species_names[0] }}</div>
        <div class="italic" v-else>{{ item.taxon_concept.full_name }}</div>
      </div>
      <div class="grid grid-cols-2">
        <div class="font-medium">{{ text.country }}</div>
        <div v-if="id === 'decision-1'" class="flex">
          <div
            v-for="(country, countryIndex) in item.country_names"
            :key="countryIndex"
          >
            <div v-if="countryIndex < item.country_names.length - 1">
              {{ country }},&nbsp;
            </div>
            <div v-else>{{ country }}</div>
          </div>
        </div>
        <div v-else>{{ item.geo_entity.name }}</div>
      </div>
      <div class="grid grid-cols-2">
        <div class="font-medium">{{ text.source_code }}</div>
        <div v-if="id === 'decision-1'" class="flex">
          <div
            v-for="(source_code, sourceCodeIndex) in item.source_codes"
            :key="sourceCodeIndex"
          >
            <div v-if="sourceCodeIndex < item.source_codes.length - 1">
              {{ source_code }},&nbsp;
            </div>
            <div v-else>{{ source_code }}</div>
          </div>
        </div>
        <div v-else class="flex">
          <div v-for="(code, index) in item.source.code" :key="index">
            <div v-if="index < item.source.code.length - 1">
              {{ code }},&nbsp;
            </div>
            <div v-else>{{ code }}</div>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-2">
        <div class="font-medium">{{ text.valid_from }}</div>
        <div v-if="id === 'decision-1'">
          {{ unixToHumanDate(item.valid_from) }}
        </div>
        <div v-else>{{ item.start_date }}</div>
      </div>
      <div class="grid grid-cols-2">
        <div class="font-medium">{{ text.valid_until }}</div>
        <div v-if="id === 'decision-1'">
          {{ unixToHumanDate(item.valid_to) }}
        </div>
        <div v-else>{{ item.is_current ? text.active : text.expired }}</div>
      </div>
      <div v-if="id === 'decision-1'" class="grid grid-cols-2">
        <div class="font-medium">{{ text.short_summary }}</div>
        <div>{{ item.summary }}</div>
      </div>
      <div class="grid grid-cols-2">
        <div class="font-medium">{{ text.doc }}</div>
        <div v-if="id === 'decision-1'">
          <div v-for="(data, dataIndex) in item.attachments" :key="dataIndex">
            <button class="underline">
              <a :href="data.url" @click.prevent="downloadItem(data)">
                {{ data.filename }}
              </a>
            </button>
          </div>
        </div>
        <div v-else>
          <a :href="item.start_event.url">
            <p class="text-blue underline">see original decision</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";

export default {
  name: "FacilityDecision",

  props: {
    item: {
      type: Object,
      required: true,
    },
    text: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    assessmentText() {
      return this.item.assessment === "Other"
        ? this.item.assessment_text
        : this.item.assessment;
    },
  },
  methods: {
    unixToHumanDate(timestamp) {
      if (timestamp) {
        return moment.unix(timestamp).format("DD/MM/YYYY");
      } else {
        return "";
      }
    },
    downloadItem({ url, filename }) {
      axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/png" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
  },
};
</script>