<template>
  <Flickity ref="flickity" :key="keyIncrementer" :options="computedOptions">
    <slot />
  </Flickity>
</template>

<script>
import Flickity from "vue-flickity";
import { debounce } from "lodash";

const DEFAULT_OPTIONS = {
  cellAlign: "left",
  pageDots: false,
  prevNextButtons: false,
};
export default {
  components: {
    Flickity,
  },
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  data() {
    return {
      keyIncrementer: 0,
    };
  },
  computed: {
    computedOptions() {
      return { ...DEFAULT_OPTIONS, ...this.options };
    },
  },
  created() {
    this.$root.$on("refresh-carousel", this.refresh);
  },
  mounted() {
    window.addEventListener("resize", this.debounceRefresh);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.debounceRefresh);
  },
  methods: {
    debounceRefresh: debounce(function () {
      this.refresh();
    }, 100),
    refresh() {
      this.$nextTick(() => {
        this.keyIncrementer++;
      });
    },
  },
};
</script>
