<template>
  <div v-if="value" class="hidden">
    {{ value }}
  </div>
</template>

<script>
export default {
  name: 'HiddenField',
  props: {
    value: {
      type: Number,
      default: NaN
    },
    name: {
      type: String,
      required: true,
    },
    inTab: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  created() {
    this.$emit('update', {
      name: this.name,
      input: this.value,
      inTab: this.inTab,
    })
  },
}
</script>
