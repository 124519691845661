<template>
  <div class="p-4 flex flex-col space-y-4">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "CardColumn",
};
</script>
