<template>
  <div class="px-10 py-14">
    <div class="mb-4 flex items-end">
      <h3>{{ text.facility_heading }}</h3>
      <p class="text-grey text-sm pb-2 px-1">
        {{ text.added_by }}
      </p>
      <p class="text-grey text-sm pb-2">
        {{ facilityDetails.created_by }}
        <span class="pr-1 pb-3">{{ unixToHumanDate(updatedAt) }}</span>
      </p>
    </div>
    <p class="mb-6 italic">{{ text.facility_subheading }}</p>
    <div class="mb-16 grid grid-cols-2 leading-10">
      <div class="font-medium">{{ text.facility_headers.name }}</div>
      <div>{{ facilityDetails.full_name }}</div>
      <div class="font-medium">{{ text.facility_headers.date }}</div>
      <div>{{ facilityDetails.establishment_date }}</div>
      <div class="font-medium">{{ text.facility_headers.website }}</div>
      <a :href="facilityDetails.url" class="underline">
        {{ facilityDetails.url }}
      </a>
      <div class="font-medium">{{ text.facility_headers.address }}</div>
      <div class="leading-normal">
        <p>{{ facilityDetails.address_line_1 }}</p>
        <p>{{ facilityDetails.address_line_2 }}</p>
        <p>{{ facilityDetails.region }}</p>
        <p>{{ facilityDetails.country }}</p>
        <p>{{ facilityDetails.zip_code }}</p>
      </div>
    </div>
    <button class="px-10 py-3 bg-blue text-white" @click="closeModal">
      {{ text.back }}
    </button>
  </div>
</template>

<script>
import moment from "moment"

export default {
  name: "FacilityDetails",

  props: {
    facilityDetails: {
      required: true,
      type: Object,
    },
    text: {
      required: true,
      type: Object,
    },
    updatedAt: {
      type: Number,
      required: false,
    },
    id: {
      required: true,
      type: String,
    },
  },

  methods: {
    unixToHumanDate(timestamp) {
      return moment.unix(timestamp).format("DD/MM/YYYY")
    },
    closeModal() {
      this.$root.$emit("close:modal", `modal-${this.id}`)
    },
  },
}
</script>
