<template>
  <div class="custom-select">
    <label v-if="selectLabel" for="select">{{ selectLabel }}</label>
    <select
      class="capitalize form-select bg-white p-4 pr-8 bg-white border rounded block w-full"
      @change="change($event)"
    >
      <option
        class="bg-white p-4 hover:bg-lilac position-bg"
        v-for="option in options"
        :key="option.id"
        :value="option.id"
        :selected="value == option.id"
      >
        {{ option.value }}
      </option>
    </select>
  </div>
</template>
<script>
export default {
  name: "DropdownSelect",

  props: {
    name: {
      type: String,
      required: false
    },
    options: {
      type: Array,
      required: true
    },
    selectLabel: {
      type: String,
      required: false
    },
    value: {
      type: [Number, String],
      required: false,
      default: 1
    },
    isParentField: {
      type: Boolean,
    },
    inTab: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  // send the default option on pageload
  mounted() {
    const selectedOption = this.options.filter((option) => option.id == this.value)[0];
    this.updateSelectedValue(selectedOption)
  },
  methods: {
    change (event) {
      const id = event.target.value,
        selectedOption = this.options.filter((option) => option.id == id)[0];

      this.updateSelectedValue(selectedOption)
    },
    updateSelectedValue(option) {
      this.$emit("update", {
        name: this.name,
        input: option,
        isParentField: this.isParentField,
        inTab: this.inTab
      })
    }
  }
}
</script>
