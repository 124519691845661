<template>
  <div v-show="isActive">
    <p class="mt-4">{{ subtitle }}</p>
    <div :class="classes">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "Tab",
  props: {
    title: {
      type: String,
      required: true,
      default: "Tab",
    },
    subtitle: {
      type: String,
      required: false,
    },
    classes: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isActive: true,
    };
  },
};
</script>
