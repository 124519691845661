<template>
  <div v-if="canBeCloned">
    <h3 v-if="subtitle" class="py-4">{{ subtitle }}</h3>
    <Tabs
      :addMore="isHidden ? false : true"
      @addMore="newTab"
      canDestroy
      :switchedTab="switchedTab + 1"
      activeClasses="bg-lilac px-6"
      inertClasses="px-6"
    >
      <Tab
        v-for="(tab, index) in tabs"
        :class="index === destroyed ? 'hidden' : ''"
        :title="`${tabTitle + ' ' + (index + 1)}`"
        :key="index"
      >
        <slot :tab="tab.fields" />
      </Tab>
    </Tabs>
  </div>
  <div v-else>
    <h3 class="font-medium">
      {{ title }}
      <span v-if="speciesName" class="italic">
        {{ speciesName }}
      </span>
    </h3>
    <h3 class="py-4">{{ subtitle }}</h3>
    <slot />
  </div>
</template>
<script>
import Tabs from '../tabs/Tabs'
import Tab from '../tabs/Tab'

export default {
  name: "FormSection",
  components: {
    Tabs,
    Tab,
  },
  props: {
    tabs: {
      type: Array,
      default: () => []
    },
    subtitle: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: false
    },
    tabTitle: {
      type: String,
      default: 'species'
    },
    speciesName: {
      type: String,
      required: false,
    },
    canBeCloned: {
      type: Boolean,
      required: false
    },
    isHidden: {
      type: Boolean,
      required: false
    },
  },

  data() {
    return {
      tabKeys: [],
      destroyed: -1,
      switchedTab: 0,
    }
  },

  mounted() {
    if (this.tabs) {
      this.tabKeys = [...this.tabs.keys()]
      this.switchedTab = this.tabKeys.length - 1
    } else {
      this.tabKeys = [0]
    }
  },

  methods: {
    newTab() {
      //needs a function to validate the input
      const newTab = [...this.tabs][this.tabKeys.length - 1]
      newTab.fields[1].input.value = NaN

      this.switchedTab = this.tabKeys.length
      this.tabKeys.push(this.tabKeys.length)
      this.tabs.push(newTab)
    },
  },
}
</script>
