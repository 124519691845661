<template>
  <div class="flex flex-col h-full">
    <div class="text-red-600 text-center" v-if="showValidationErrors">
      {{ "Incorrect Login details" }}
    </div>
    <form @submit.prevent="submitForm">
      <div v-for="inputType in inputTypes" :key="inputType">
        <label class="block mb-2" :for="inputType">
          {{ capitalize(inputType) }}
        </label>
        <input
          @change="record"
          class="mb-4 p-3 border rounded"
          :type="inputType"
          :name="inputType"
        />
      </div>
      <button
        type="submit"
        class="cursor-pointer px-8 py-2 my-4 block bg-blue text-white"
      >
        Login
      </button>
    </form>
    <footer class="hidden mt-auto max-w-3/4 md:block md:max-w-full">
      <hr />
      <div class="flex justify-between py-4">
        <span class="self-center" title="copyright" />
        <div class="flex justify-between">
          <EuFlag class="h-8" />
          <a class="w-24 block self-center" href="https://www.unep-wcmc.org/">
            <img
              src="https://s3.amazonaws.com/wcmc.logo/UNEP_WCMC_logo_black.svg"
              alt="unep-wcmc logo"
            />
          </a>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import EuFlag from "../icons/EuFlag";
import axios from "axios";

export default {
  name: "LoginForm",
  components: {
    EuFlag,
  },
  props: {
    inputTypes: {
      type: Array,
      default: function () {
        return ["email", "password"];
      },
      required: false,
    },
  },

  data() {
    return {
      email: "",
      password: "",
      showValidationErrors: false,
      fieldsWithErrors: 0,
    };
  },

  methods: {
    record(event) {
      if (event.target.type == "email") {
        this.email = event.target.value;
      } else {
        this.password = event.target.value;
      }
    },
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    submitForm() {
      const hasErrors = this.fieldsWithErrors > 0;
      if (hasErrors) {
        this.showValidationErrors = true;
      } else {
        const csrf = document
          .querySelectorAll('meta[name="csrf-token"]')[0]
          .getAttribute("content");
        axios.defaults.headers.common["X-CSRF-Token"] = csrf;
        axios.defaults.headers.common["Accept"] = "application/json";
        const data = {
          email: this.email,
          password: this.password,
        };
        axios
          .post("/users/sign_in", { user: data })
          .then((res) => {
            window.location = "/search";
          })
          .catch((error) => {
            this.showValidationErrors = true;
          });
      }
    },
  },
};
</script>
