<template>
  <div>
    <div class="flex justify-center min-h-screen-1/2 bg-green-light">
      <h2 class="self-center">{{ localeBefore_you_start.title }}</h2>
    </div>
    <div class="mt-10 px-96 py-site">
      <div class="text-lg">
        {{ localeBefore_you_start.intro }}
      </div>
    </div>
    <div class="grid grid-cols-3 gap-x-4 container mx-auto mt-10 mb-14">
      <div class="text-4xl col-span-3 font-medium mb-6">
        {{ localeBefore_you_start.bodyTitle }}
      </div>
      <div
        v-for="(block, stepIndex) in localeBefore_you_start.blocks"
        :key="stepIndex"
      >
        <StepBox
          :title="block.title"
          :step="stepIndex + 1"
          :content="block"
          :stepsNumber="steps"
        />
      </div>
    </div>
    <div class="bg-grey-light">
      <div class="container mx-auto flex justify-end">
        <a
          href="/save-and-complete-later"
          class="underline self-center text-lg"
          >{{ localeBefore_you_start.footer.link }}</a
        >
        <button class="bg-blue ml-4 my-6 py-3">
          <a href="/application-start-page" class="text-xl text-white px-8">{{
            localeBefore_you_start.footer.button
          }}</a>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import StepBox from "../applicationStart/StepBox";

  export default {
    name: "BeforeYouStart",
    components: {
      StepBox,
    },

    props: {
      localeBefore_you_start: {
        required: true,
        type: Object,
      },
    },

    computed: {
      steps() {
        return this.localeBefore_you_start.blocks.length;
      },
    },
  };
</script>
