<template>
  <header>
    <div :class="`flex items-stretch min-h-96 ${flexDirectionClass}`">
      <div
        :class="`p-site min-h-screen-1/2 flex-grow ${backgroundColorClass}`"
        :title="localeHero.title"
      >
        <h2 v-if="localeHero.title">{{ localeHero.title }}</h2>
        <h3 v-if="localeHero.subtitle">{{ localeHero.subtitle }}</h3>
      </div>
      <div
        class="min-w-1/2 min-h-screen-1/2 flex-grow position-bg"
        :style="{ backgroundImage: `url('${heroImg}')` }"
        :title="localeHero.title"
      />
    </div>
    <div v-if="localeHero.intro" class="px-96 py-site">
      <h6 v-html="localeHero.intro" />
    </div>
  </header>
</template>

<script>
export default {
  name: "Hero",
  props: {
    localeHero: {
      required: true,
      type: Object,
    },
    heroImg: {
      type: String,
    },
    backgroundColorClass: {
      required: false,
      type: String,
    },
    flexDirectionClass: {
      required: false,
      type: String,
      default: "flex-row",
    },
  },
}
</script>
