<template>
  <div>
    <input type="date" class="form-control p-3 border rounded" v-model="date" >
  </div>
</template>

<script>
export default {
  name: "DateInput",
  props: {
    name: {
      type: String,
      required: true,
    },
    inTab: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      date: this.value,
    }
  },
  created() {
    this.$emit("update", {
      name: this.name,
      input: this.date,
      inTab: this.inTab,
    })
  },
  updated() {
    this.$emit("update", {
      name: this.name,
      input: this.date,
      inTab: this.inTab,
    })
  },
}
</script>
