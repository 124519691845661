<template>
  <div>
    <div
      v-show="errors"
      class="animated-banner w-full px-96 flex bg-danger fixed top-0 left-0"
    >
      <span
        v-for="(error, index) in errors"
        :key="index"
        class="text-white text-center p-3"
      >
        {{ `${error}   ` }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorBanner",
  props: {
    errors: {
      type: Array,
      required: true
    }
  }
}
</script>

