<template>
  <table class="w-11/12 mt-6 table-auto mx-auto mb-10">
    <thead>
      <tr>
        <th class="px-4 text-left">
          <button @click="sort('facility_name')" class="flex font-bold">
            {{ localeApplications.button.facility }}
            <Sort class="w-12 mt-1" />
          </button>
        </th>
        <th class="px-4 text-left" @click="sort('last_updated_at')">
          <button class="flex font-bold">
            {{ localeApplications.header.last_updated }}
            <Sort class="w-12 mt-1" />
          </button>
        </th>
        <th class="px-4 text-left" @click="sort('country_name')">
          <button class="flex font-bold">
            {{ localeApplications.header.facility_country }}
            <Sort class="w-12 mt-1" />
          </button>
        </th>
        <th class="px-4 text-left" @click="sort('species_name')">
          <button class="flex font-bold">
            {{ localeApplications.header.species }}
            <Sort class="w-12 mt-1" />
          </button>
        </th>
        <th
          class="px-4 text-left"
          @click="sort('decision')"
        >
          <button class="flex font-bold">
            {{ localeApplications.header.application_decision }}
            <Sort class="w-12 mt-1" />
          </button>
        </th>
        <th class="px-4 py-6 text-left"></th>
        <th class="pl-4 pr-10 py-6 text-left"></th>
        <th class="pl-4 pr-10 py-6 text-left"></th>
      </tr>
    </thead>
    <tbody v-for="(data, dataIndex) in dataToShow" :key="dataIndex">
      <tr class="mt-6">
        <td class="px-4 py-6 bg-gray-100 border-b-4 border-white">
          {{ data.facility_name }}
        </td>
        <td class="px-4 py-6 bg-gray-100 border-b-4 border-white">
          {{ unixToHumanDate(data.last_updated_at) }}
        </td>
        <td class="px-4 py-6 bg-gray-100 border-b-4 border-white">
          {{ data.facility_country }}
        </td>
        <td class="px-4 py-6 bg-gray-100 border-b-4 border-white italic">
          {{ data.species_name }}
        </td>
        <td class="px-4 py-6 bg-gray-100 border-b-4 border-white">
          {{ data.application_decision }}
        </td>
        <td class="w-40 bg-gray-100 border-b-4 border-white whitespace-nowrap">
          <DynamicEditButton
            :label="localeApplications.button.facility_species[data.warning_type]"
            :warningType="data.warning_type"
            :facilitySpeciesStockPath="data.facility_species_stock_path"
          />
        </td>
        <td class="px-4 py-6 bg-gray-100 border-b-4 border-white">
          <button>
            <a :href="`/applications/${data.id}/edit`">
              <Edit class="w-6" />
            </a>
          </button>
        </td>
        <td class="px-4 bg-gray-100 border-b-4 border-white">
          <a :href="`/facilities/${data.facility_id}`">
            <RightArrow class="w-12" />
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import axios from "axios";
import DynamicEditButton from "../facility_species_stocks/DynamicEditButton";
import Edit from "../icons/Edit";
import moment from "moment";
import RightArrow from "../icons/RightArrow";
import Sort from "../icons/Sort";

export default {
  name: "ApplicationsTable",
  components: {
    DynamicEditButton,
    Edit,
    RightArrow,
    Sort,
  },
  props: {
    localeApplications: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dataToShow: [],
      urlWithParams: "",
    };
  },
  created() {
    axios
      .get("/applications.json")
      .then((response) => {
        this.dataToShow = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  },

  methods: {
    sort(column) {
      if (this.urlWithParams === "") {
        this.urlWithParams = `/applications.json?sort_by=${column}`;
      } else if (this.urlWithParams === `/applications.json?sort_by=${column}`)
        this.urlWithParams = `/applications.json?order=reverse&sort_by=${column}`;
      else {
        this.urlWithParams = `/applications.json?sort_by=${column}`;
      }
      axios
        .get(this.urlWithParams)
        .then((response) => {
          this.dataToShow = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    unixToHumanDate(timestamp) {
      return moment.unix(timestamp).format("DD/MM/YYYY");
    },
  },
};
</script>
