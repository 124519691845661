<template>
  <div class="px-10 py-14">
    <h3 class="mb-3">
      {{ text.title.changes }}
      <span class="italic">{{ title }}</span>
    </h3>
    <p class="text-grey-dark pb-5">
      {{ text.added_by }} {{ dataObject.added_by }} {{ dataObject.added_on }} •
      <span class="text-blue underline">
        <button @click="redirect">{{ text.update }}</button>
      </span>
    </p>
    <table
      class="table-auto w-full mb-6 text-left"
      v-if="dataObject.annual_stocks"
    >
      <thead>
        <th>{{ text.year }}</th>
        <th>{{ text.number }}</th>
        <th>{{ text.mortality_rate }}</th>
      </thead>
      <tbody v-for="(item, index) in dataObject.annual_stocks" :key="index">
        <tr>
          <td>{{ item.year }}</td>
          <td>{{ item.annual_production_number }}</td>
          <td>{{ item.annual_production_mortality_rate }}</td>
        </tr>
      </tbody>
    </table>
    <div v-else>
      <table v-if="dataObject.changes" class="table-auto w-full mb-3 text-left">
        <thead>
          <tr>
            <th v-for="(item, key) in dataObject.changes[0]" :key="key">{{ key }}</th>
          </tr>
        </thead>
        <tbody v-for="(item, index) in dataObject.changes" :key="index">
          <tr>
            <td v-for="(value, key) in item" :key="key">{{ value }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <button class="w-24 h-10 bg-blue bottom-10 text-white" @click="closeModal">
      {{ text.back }}
    </button>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import { setAxiosHeaders } from "../../../helpers/axios";
import Turbolinks from "turbolinks";

export default {
  name: "ChangesOverTime",

  props: {
    dataObject: {
      required: false,
      type: Object,
    },
    text: {
      required: true,
      type: Object,
    },
    title: {
      required: true,
      type: String,
    },
    id: {
      required: true,
      type: String,
    },
    redirectPath: {
      required: false,
      type: String,
    },
  },

  mounted() {
    setAxiosHeaders(axios)
  },

  methods: {
    unixToHumanDate(timestamp) {
      return moment.unix(timestamp).format("DD/MM/YYYY")
    },
    closeModal() {
      this.$root.$emit("close:modal", `modal-${this.id}`)
    },
    redirect() {
      Turbolinks.visit(this.redirectPath)
    },
  },
}
</script>
