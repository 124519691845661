<template>
  <button
    class="cursor-pointer"
    :class="[buttonClasses, disabled ? ' bg-grey-medium' : '']"
    id="person-update-panel--save"
    type="submit"
    @click.prevent="submitResults()"
    :disabled="disabled"
  >
    {{ buttonText }}
  </button>
</template>

<script>
export default {
  name: "SubmitButton",
  props: {
    bgColor: {
      type: String,
      required: false,
    },
    textColor: {
      type: String,
      required: false,
    },
    buttonText: {
      type: String,
      required: false,
      default: "Submit",
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    buttonClasses() {
      return `p-3 text-lg ${this.bgColor} ${this.textColor} `;
    },
  },
  methods: {
    submitResults() {
      this.$emit("submit");
    },
  },
};
</script>
