var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"py-24 px-96 max-h-100 overlay position-bg",style:({ backgroundImage: ("url('" + _vm.bgImage + "')") })},[_c('h2',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.header))]),_vm._v(" "),_c('Tabs',{attrs:{"activeClasses":"mr-5","inertClasses":"mr-5"}},[_c('Tab',{attrs:{"title":_vm.tabs.taxonCountry.title,"subtitle":_vm.tabs.taxonCountry.subtitle,"classes":"my-6 grid grid-cols-2/2/1 grid-cols-ie11-2-2-1 gap-4 items-start"}},[_c('Autocomplete',{attrs:{"config":{
            baseUrl: _vm.endpoints.TAXON_SEARCH_URL,
            characterStart: 3,
            param: _vm.endpoints.TAXON_SEARCH_PARAM,
            searchKey: 'full_name',
            showMatching: true,
          },"heading":_vm.inputHeadings.taxon,"placeholder":_vm.placeholder.taxon,"name":"taxa"},on:{"isopen":_vm.disableSubmit,"update":_vm.taxaInputSelect}}),_vm._v(" "),_c('Autocomplete',{attrs:{"config":{
            characterStart: 0,
            endpoint: _vm.endpoints.COUNTRY_SEARCH_URL,
            searchKey: 'name',
            callOnce: true,
          },"dropdown":"","heading":_vm.inputHeadings.country,"placeholder":_vm.placeholder.country,"arrow":_vm.arrowImg,"name":"country"},on:{"update":_vm.countryInputSelect,"isopen":_vm.disableSubmit}}),_vm._v(" "),_c('SubmitButton',{attrs:{"textColor":"text-white","bgColor":"bg-blue","buttonText":_vm.buttonText,"disabled":_vm.autocompleteOpen},on:{"submit":_vm.submit}})],1),_vm._v(" "),_c('Tab',{attrs:{"classes":"my-6","title":_vm.tabs.facility.title,"subtitle":_vm.tabs.facility.subtitle}},[_c('Autocomplete',{attrs:{"config":{
            characterStart: 0,
            baseUrl: _vm.endpoints.FACILITY_SEARCH_URL,
            param: _vm.endpoints.FACILITES_SEARCH_PARAM,
            searchKey: 'full_name',
          },"placeholder":_vm.placeholder.facility,"arrow":_vm.arrowImg,"name":"facility"},on:{"update":_vm.autosubmit}})],1)],1)],1),_vm._v(" "),_c('div',{staticClass:"pl-site mt-14 mb-6"},[_c('div',{staticClass:"flex justify-between items-center"},[_c('p',{staticClass:"text-4xl font-medium"},[_vm._v(_vm._s(_vm.carouselContent.title))]),_vm._v(" "),_c('a',{staticClass:"underline mr-6 pr-site",attrs:{"href":'/forum/threads'}},[_vm._v(_vm._s(_vm.carouselContent.link))])])]),_vm._v(" "),_c('Carousel',{key:_vm.carouselKey,staticClass:"mb-32 pl-site"},_vm._l((_vm.itemsForCarousel),function(item,itemIndex){return _c('Item',{key:itemIndex,attrs:{"title":item.name,"image":item.image_url,"buttonText":_vm.carouselContent.button,"forumCategoryUrl":item.forum_category_url}})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }