<template>
  <div v-if="refused">
    <span
      class="pb-0.5 text-lg flex justify-center bg-red-600 w-8 h-8 rounded-full text-white"
    >
      &#9888;
    </span>
  </div>
</template>

<script>
export default {
  props: {
    refused: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
