export const Endpoints = Object.freeze({
  TAXON_SEARCH_URL: "/api/v1/sapi/species_autocomplete",
  TAXON_SEARCH_PARAM: "query",
  COUNTRY_SEARCH_URL: "/api/v1/sapi/countries",
  COUNTRY_SEARCH_PARAM: "importing_member_state",
  FACILITY_SEARCH_URL: "/facilities_autocomplete",
  FACILITES_SEARCH_PARAM: "facility[name]",
  SRG_URL: "/api/v1/sapi/eu_decisions",
  SRG_PARAM_TAXON: "taxon_concept_ids[]",
  SRG_PARAM_COUNTRY: "geo_entity_ids[]"
});