<template>
  <div>
    <input
      class="text-black pb-4"
      @change="handleFileUploads($event)"
      :name="name"
      type="file"
      :multiple="multiple"
    >
    <ul v-if="files.length > 0" :files="files">
      <li class="text-black pb-4" v-for="(file, index) in files" :key="index">
        <div v-if="progress === 1">file loading...</div>
        <div v-else>
          <span>{{ file.filename }}</span>
          <span
            class="
              px-2
              py-1
              ml-6
              bg-danger
              text-xs text-white
              cursor-pointer
              rounded-full
            "
            @click.prevent="deleteFile(index)"
          >
            x
          </span>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import axios from "axios";
import { DirectUpload } from "activestorage";
import { setAxiosHeaders } from "../../helpers/axios";

export default {
  name: "FileUpload",

  props: {
    allowMultiple: {
      default: false,
      type: Boolean,
    },
    name: {
      type: String,
      required: true,
    },
    inTab: {
      type: Boolean,
      required: false,
      default: false,
    },
    blobs: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      destroyedData: [],
      files: [],
      uploadData: [],
      progress: 0,
      index: 0,
    };
  },

  computed: {
    multiple() {
      return this.allowMultiple ? "multiple" : "";
    },

    uploadedBlobs() {
      return this.blobs.documents_attributes.map((b) => b.blob);
    },
  },

  mounted() {
    setAxiosHeaders(axios)
    this.uploadedBlobs.forEach((upload) => {
      this.files.push(upload)
    })
    this.blobs.documents_attributes.forEach((item) => {
      this.uploadData.push({ attachment: item.attachment, id: item.id })
    })
  },

  methods: {
    async handleFileUploads(document) {
      // for each document upleaded we need to create a blob and push it to the uploadData in the form that postData requires
      for (let doc of document.target.files) {
        const upload = new DirectUpload(
          doc,
          "/rails/active_storage/direct_uploads",
          this
        )
        // TODO make a better progress bar
        this.progress = 1
        await upload.create((error, blob) => {
          if (error) {
            console.log(error)
          } else {
            this.files.push(blob)
            this.uploadData.push({ attachment: blob.signed_id });
          }
          this.progress = 0
        })
      }
      this.postData()
    },

    postData() {
      //append destroyed items
      this.destroyedData.forEach((el) => (el["_destroy"] = true))
      this.uploadData = this.uploadData.concat(this.destroyedData)

      this.$emit("update", {
        name: this.name,
        input: {
          postData: this.uploadData,
        },
        inTab: this.inTab,
      })
    },

    deleteFile(index) {
      if (this.uploadData[index].id) {
        this.destroyedData.push(this.uploadData[index])
      }
      this.uploadData = this.uploadData.filter((value, i) => {
        return i !== index
      })
      this.files = this.files.filter((value, i) => {
        return i !== index
      })
      this.postData()
    },
  },
};
</script>
