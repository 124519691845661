<template>
  <div>
    <div v-for="(option, key) in filteredOptions" :key="key">
      <input
        type="checkbox"
        :id="option.id"
        :value="option.id"
        class="form-checkbox h-8 w-8 mb-4 rounded-none border text-green-500"
        v-model="checkedValues"
      >
      <label :for="option" class="ml-6 text-base font-thin">
        {{ option.value }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "Checkboxes",
  props: {
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    isDependent: {
      type: Boolean,
      default: false,
    },
    parentField: {
      type: Array,
      required: false,
    },
    inTab: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: Array,
      required: true,
    }
  },
  
  data() {
    return {
      checkedValues: this.value,
    }
  },

  computed: {
    filteredOptions() {
      // filter based on parent input id
      return this.parentField
        ? this.options.filter(
            (option) => option.filter_id === this.parentField[1]
          )
        : this.options;
    },
    noOptions() {
      return !this.filteredOptions.length > 0 
    }
  },

  watch: {
    parentField: function (value, oldValue) {
      if (value !== oldValue && oldValue[1] !== "") {
        this.checkedValues = []
      } else {
        this.checkedValues = this.value
      }
    },
  },

  updated() {
    this.$emit("update", {
      name: this.name,
      input: this.checkedValues,
      inTab: this.inTab,
      noOptions: this.noOptions
    })
  },
};
</script>
