<template>
  <textarea
    class="form-textarea w-full mb-6 block border rounded"
    :name="name"
    :maxlength="maxlength"
    v-model="inputValues"
  />
</template>

<script>
export default {
  name: "Textarea",
  props: {
    name: {
      type: String,
      required: true,
    },
    inTab: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: String,
      required: true,
      default: "",
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      inputValues: this.value,
      maxlength: this.options.maxlength || 150,
    }
  },
  updated() {
    this.$emit("update", {
      name: this.name,
      input: this.inputValues,
      inTab: this.inTab,
    })
  }
}
</script>
