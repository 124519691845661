<template>
  <div>
    <div class="mx-96 py-site lg:p-site">
      <FormWrapper :formData="formData" />
    </div>
  </div>
</template>
<script>
import FormWrapper from "../forms/FormWrapper"

export default {
  name: "SpeciesForm",
  components: {
    FormWrapper,
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
    formLocale: {
      type: Object,
      required: false,
    },
  },
}
</script>
