import Vue from "vue/dist/vue.esm";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    searchResults: [],
    searchValues: {},
  },
  mutations: {
    updateResults(state, value) {
      state.searchResults = value;
      // store search results in local storage
      let parsed = JSON.stringify(state.searchResults);
      localStorage.setItem("searchResults", parsed);
    },
    updateSearchValues(state, value) {
      state.searchValues = value;
      let parsed = JSON.stringify(state.searchValues);
      localStorage.setItem("searchValues", parsed);
    },
  },
  actions: {
    updateValues({ state, commit }, obj) {
      if (state.searchValues) {
        commit("updateSearchValues", obj);
      }
    },
    updateResults({ state, commit }, arr) {
      if (state.searchResults) {
        commit("updateResults", arr);
      }
    },
  },
  getters: {},
});
