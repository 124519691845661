<template>
  <div>
    <div v-if="hasDecisions">
      <div v-if="activeDecision" class="flex">
        <RedFlag class="mr-4" />
        <p class="font-medium mt-0.5 mb-3">
          {{ labels[`heading_${decisionType}`]["label"] }}
        </p>
      </div>
      <div v-else class="font-medium mb-3">
        {{ labels[`heading_${decisionType}`]["label_no"] }}
      </div>
      <div class="flex">
        <div v-if="activeDecision" class="flex">
          <RedFlag class="mr-4 invisible" />
          <!-- TODO refactor labels -->
          {{ labels[`heading_${decisionType}`]["link"] }}
        </div>
        <div v-else-if="hasDecisions">
          {{ labels[`heading_${decisionType}`]["show_history"] }}
        </div>
        <Modal
          :id="id"
          :localeModal="localeModal"
          :url="url"
          :activeDecision="activeDecision"
        />
      </div>
    </div>
    <div class="font-medium mb-3" v-else>
      {{ labels[`heading_${decisionType}`]["label_no"] }}
    </div>
  </div>
</template>

<script>
import RedFlag from "../facility/RedFlag.vue"
import Modal from "../facility/modals/Modal"

export default {
  name: "DecisionBlock",

  components: { Modal, RedFlag },

  props: {
    hasDecisions: {
      type: Boolean,
      default: false,
    },

    activeDecision: {
      type: Boolean,
      default: false,
    },

    decisionType: {
      type: String,
      required: true,
    },

    localeModal: {
      type: Object,
      required: true,
    },

    url: {
      type: String,
      required: false,
    },

    labels: {
      type: Object,
      required: true,
    },

    id: {
      type: String,
      required: false,
    }
  },
}
</script>