<template>
  <div>
    <FacilityInfo
      :facilityDetails="facilityDetails"
      :country="country"
      :labels="labels"
      :localeModal="localeModal"
      :facilityLevelDecisionsUrl="facilityLevelDecisionsUrl"
      :lastApplicationDate="lastApplicationDate"
      :updatedAt="updatedAt"
      :data="data"
      :facilityLevelDecisionsCount="facilityLevelDecisionsCount"
      :activeDecision="activeDecision"
    />
    <div class="mx-site">
      <jump-links @go="scrollToElement" :text="labels.jump_links" />
      <card-tabs :labels="labels" :localeModal="localeModal" :data="data" />
      <LargeDot
        class="mt-20"
        ref="comments"
        :label="{
          text: labels.comments.heading,
          classesText: 'text-2xl',
          classesDot:
            'w-5 h-5 ml-1 mr-2 inline-block rounded-full bg-purple-300',
        }"
      />
    </div>
  </div>
</template>

<script>
import CardTabs from "../card/CardTabs.vue";
import FacilityInfo from "./FacilityInfo.vue";
import JumpLinks from "./JumpLinks.vue";
import LargeDot from "../atoms/LargeDot.vue";

export default {
  name: "FacilityPage",
  components: {
    FacilityInfo,
    CardTabs,
    JumpLinks,
    LargeDot,
  },
  props: {
    facilityDetails: {
      type: Object,
      required: true,
    },
    facilityLevelDecisionsUrl: {
      type: String,
      required: false,
    },
    lastApplicationDate: {
      type: Number,
      required: false,
    },
    updatedAt: {
      type: Number,
      required: false,
    },
    country: {
      type: Object,
      required: true,
    },
    labels: {
      type: Object,
      required: true,
    },
    localeModal: {
      type: Object,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    backgroundImage: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    text: {
      required: false,
      type: String,
    },
    button: {
      required: false,
      type: Object,
    },
    facilityLevelDecisionsCount: {
      required: false,
      type: Number,
    },
    activeDecision: {
      required: false,
      type: Boolean,
    },
  },

  methods: {
    scrollToElement(item) {
      if (item == 2) {
        this.$refs.comments.$el.scrollIntoView({ behavior: "smooth" });
      } else {
        this.$root.$emit("jump", item);
      }
    },
  },
};
</script>
