<template>
  <div>
    <Hero
      :heroImg="heroImg"
      :localeHero="localeFaq.hero"
      :backgroundColorClass="'bg-lilac'"
    />
    <Accordion :questions="localeFaq.questions" />
    <Banner
      :backgroundImage="bannerImg"
      :title="localeFaq.banner.title"
      :button="localeFaq.banner.button"
    />
  </div>
</template>

<script>
import Hero from "../hero/Hero.vue";
import Accordion from "../accordion/Accordion.vue";
import Banner from "../banner/Banner.vue";

export default {
  name: "FaqPage",

  components: {
    Banner,
    Accordion,
    Hero,
  },
  props: {
    localeFaq: {
      required: true,
      type: Object,
    },
    heroImg: {
      required: false,
      type: String,
    },
    bannerImg: {
      required: false,
      type: String,
    },
  },
};
</script>
