<template>
  <div :class="classes">
    <div
      v-show="hasResults"
      v-for="(result, index) in results"
      :key="index"
    >
      <Card v-bind="{ card: result, calendarIcon, lastUpdatedText }" />
    </div>
  </div>
</template>
<script>
import Card from "../card/Card";

export default {
  name: "CardGrid",
  components: {
    Card,
  },
  props: {
    calendarIcon: {
      type: String,
      required: true,
    },
    results: {
      type: Array,
      required: true,
    },
    lastUpdatedText: {
      type: String,
      required: true
    },
    displayNumber: {
      type: Number,
      required: false,
      default: 6,
    },
    classes: {
      type: String,
      required: false,
      default: "mx-site pt-3 mb-20 grid gap-10 grid-cols-3"
    }
  },
  data() {
    return {
      currentResults: 0,
    };
  },
  computed: {
    hasResults() {
      return this.results.length > 0;
    },
    // TODO: fix this for mobile users, and make a request to backend every time page is scrolled to bottom.
    // cardsVsResults() {
    //   return this.currentResults < this.results.length;
    // },
  },
  // created() {
    // this.loadMoreCards(this.displayNumber);
  // },
  // mounted() {
    // window.ontouchmove = () => {
    //   //check if user reached bottom of the page
    //   let bottomOfWindow =
    //     document.documentElement.scrollTop + window.innerHeight ===
    //     document.documentElement.offsetHeight;
    //   if (bottomOfWindow && this.cardsVsResults) {
    //     this.currentResults += this.displayNumber
    //     this.loadMoreCards(this.displayNumber);
    //   }
    // };
  // },

  // methods: {
  //   loadMoreCards(number) {
  //     let nextLength = this.currentResults + number;
  //     return this.results.slice(0, nextLength);
  //   },
  // },
};
</script>
