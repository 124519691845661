<template>
  <div
    class="m-site px-52 py-36 text-white text-center position-bg flex justify-center"
    :style="{ backgroundImage: `url('${backgroundImage}')` }"
  >
    <div>
      <a v-if="titleLink" :href="titleLink">
        <h2 class="py-6 flex items-center">
          <strong>{{ title }}</strong>
          <span
            v-if="titleLink"
            class="w-6 h-6 ml-4 mt-1 px-2 inline-block bg-grey-light rounded-full text-blue text-base leading-5"
          >
            &#8250;
          </span>
        </h2>
      </a>
      <h2 v-else class="py-6 flex items-center">
        <strong>{{ title }}</strong>
      </h2>
      <p class="py-6" v-if="text" v-html="text" />
      <a v-if="!titleLink" :href="button.link">
        {{ button.text }}
        <span
          class="w-6 h-6 ml-3 px-2 inline-block bg-grey-light rounded-full text-blue leading-5"
        >
          &#8250;
        </span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Banner",
  props: {
    backgroundImage: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    titleLink: {
      required: false,
      type: String,
    },
    text: {
      required: false,
      type: String,
    },
    button: {
      required: false,
      type: Object,
    },
  },
};
</script>
