<template>
  <div class="mb-6 mt-4">
    <span :class="label.classesDot"/>
    <span v-if="label" :class="label.classesText">{{ label.text }}</span>
  </div>
</template>

<script>
export default {
  name: "LargeDot",
  props: {
    label: {
      type: Object,
      required: false,
    },
  },
};
</script>
