var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Tabs',{attrs:{"activeClasses":"px-5 italic","inertClasses":"px-5 italic"}},_vm._l((_vm.data),function(species,speciesIndex){return _c('Tab',{key:speciesIndex,attrs:{"title":species.species_name}},[_c('h3',{staticClass:"font-medium my-6"},[_vm._v(_vm._s(_vm.labels.data))]),_vm._v(" "),_vm._l((species.applications),function(application,applicationIndex){return _c('div',{key:applicationIndex},[_c('div',{staticClass:"flex items-center justify-between"},[(applicationIndex === 0)?_c('LargeDot',{ref:("application" + speciesIndex),refInFor:true,attrs:{"label":{
              text: 'Applications',
              classesText: 'text-2xl',
              classesDot:
                'w-5 h-5 ml-1 mr-2 inline-block rounded-full bg-red-200',
            }}}):_vm._e(),_vm._v(" "),(applicationIndex > 0)?_c('div'):_vm._e(),_vm._v(" "),(
              (_vm.showApplications === 'Hide previous applications' &&
                applicationIndex > 1) ||
              applicationIndex === 0
            )?_c('div',{staticClass:"flex items-center"}):_vm._e()],1),_vm._v(" "),_c('CardFacilityPage',{staticClass:"mb-2 -mt-16",class:{
            hidden:
              applicationIndex > 0 &&
              _vm.showApplications === 'View previous applications',
          },attrs:{"id":"application","application":application,"applicationIndex":applicationIndex,"speciesIndex":speciesIndex,"labels":_vm.labels,"species":species,"localeModal":_vm.localeModal,"columns":"3","index":applicationIndex}}),_vm._v(" "),(_vm.viewPreviousApplication(applicationIndex, species.applications))?_c('div',{staticClass:"flex justify-between"},[_c('p',{staticClass:"text-grey underline my-6",on:{"click":_vm.toggleShowApplications}},[_vm._v("\n            "+_vm._s(_vm.showApplications)+"\n          ")])]):_vm._e()],1)}),_vm._v(" "),_c('LargeDot',{ref:("facility" + speciesIndex),refInFor:true,attrs:{"label":{
          text: 'Details for species',
          classesText: 'text-2xl',
          classesDot: 'w-5 h-5 ml-1 mr-2 mt-12 inline-block rounded-full bg-green',
        }}}),_vm._v(" "),_c('CardFacilityPage',{staticClass:"mb-2",attrs:{"labels":_vm.labels,"species":species,"speciesIndex":speciesIndex,"localeModal":_vm.localeModal,"columns":"4"}})],2)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }