// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You"re encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it"ll be compiled.

import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from "vue/dist/vue.esm";
import Turbolinks from "turbolinks";
import TurbolinksAdapter from "vue-turbolinks";

import store from "../store/store.js";
import "../../assets/stylesheets/application";

import Autocomplete from "../components/autocomplete/Autocomplete";
import Banner from "../components/banner/Banner";
import BeforeYouStart from "../components/pages/BeforeYouStart";
import CardTabs from "../components/card/CardTabs.vue";
import Chat from "../components/icons/Chat";
import ChangesOverTime from "../components/facility/modals/ChangesOverTime";
import CondensedForm from "../components/forms/CondensedForm";
import DecisionModal from "../components/facility/modals/DecisionModal";
import DocumentDownloads from "../components/facility/modals/DocumentDownloads";
import DecisionTable from "../components/decisionComponents/DecisionTable";
import Edit from "../components/icons/Edit";
import EuFlag from "../components/icons/EuFlag"
import FacilityDetails from "../components/facility/modals/FacilityDetails";
import FacilityInfo from "../components/facility/FacilityInfo";
import FacilityPage from "../components/facility/FacilityPage";
import FaqPage from "../components/pages/FaqPage";
import FormWrapper from "../components/forms/FormWrapper";
import Hero from "../components/hero/Hero";
import JumpLinks from "../components/facility/JumpLinks";
import LargeDot from "../components/atoms/LargeDot.vue";
import LoginForm from "../components/forms/LoginForm";
import NavPopup from "../components/nav/NavPopup";
import ReadyToGo from "../components/pages/ReadyToGo";
import RailsAutocomplete from "../components/rails_form_wrappers/RailsAutocomplete";
import SearchPane from "../components/search/SearchPane";
import SearchResults from "../components/search/SearchResults";
import UpdateFacilityDetails from "../components/facility/modals/UpdateFacilityDetails";
import ApplicationsTable from "../components/applications/ApplicationsTable";
import SpeciesForm from "../components/forms/SpeciesForm"
import SideHero from "../components/hero/SideHero"

Vue.config.productionTip = false;
Vue.use(TurbolinksAdapter);

const images = require.context("../images", true);

Turbolinks.start();

document.addEventListener("turbolinks:load", () => {
  if (document.getElementById("v-app")) {
    new Vue({
      el: "#v-app",
      store,
      images,
      components: {
        Autocomplete,
        Banner,
        BeforeYouStart,
        ApplicationsTable,
        CardTabs,
        ChangesOverTime,
        Chat,
        CondensedForm,
        DecisionModal,
        DocumentDownloads,
        DecisionTable,
        Edit,
        EuFlag,
        FacilityDetails,
        FacilityInfo,
        FacilityPage,
        FaqPage,
        FormWrapper,
        Hero,
        JumpLinks,
        LargeDot,
        LoginForm,
        NavPopup,
        RailsAutocomplete,
        ReadyToGo,
        SearchPane,
        SearchResults,
        UpdateFacilityDetails,
        SpeciesForm,
        SideHero
      },
    });
  }
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag "rails.png" %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context("../images", true)
// const imagePath = (name) => images(name, true)
