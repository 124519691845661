<template>
  <div>
    <ul class="flex">
      <li
        v-for="(tab, index) in tabs"
        :key="index"
        @click.prevent="selectTab(index)"
        :class="[
          index === selectedIndex ? activeStyles : inertStyles,
          destroyed.includes(index) ? 'hidden': '' ,
        ]"
        class="relative py-5 text-xl font-normal"
      >
        <span
          v-if="canDestroy && index === selectedIndex"
          class="absolute cursor-pointer top-0 right-0 m-1 leading-none w-4 h-4 rounded-full bg-danger text-white text-sm text-center"
          @click.stop="emitDestroy"
        > 
          x
        </span>
        <p class="cursor-pointer">
          {{ tab.title }}
        </p>
      </li>
      <li
        :class="`${inertStyles} py-5 text-base font-normal`"
        v-if="addMore"
        title="addMoreText"
        @click.prevent="emitSelect"
      >
        <p class="cursor-pointer">
          {{ addMoreText }}
        </p>
      </li>
    </ul>
    <hr >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  props: {
    activeClasses: {
      type: String,
      required: false,
    },
    addMore: {
      type: Boolean,
      required: false,
      default: false,
    },
    addMoreText: {
      type: String,
      required: false,
      default: '+ Add more',
    },
    canDestroy: {
      type: Boolean,
      required: false,
      default: false,
    },
    inertClasses: {
      type: String,
      required: false,
    },
    switchedTab: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      selectedIndex: 0,
      destroyed: [],
      tabs: [],
      active: 'border-b-blue text-black font-medium',
      inert: 'text-grey underline',
    };
  },
  computed: {
    tabsLength() {
      return this.tabs.length;
    },
    activeStyles() {
      return this.active + ' ' + this.activeClasses;
    },
    inertStyles() {
      return this.inert + ' ' + this.inertClasses;
    },
    activeTabs() {
      return this.tabs.filter(item => !this.destroyed.includes(this.tabs.indexOf(item)))
    }
  },
  created() {
    this.tabs = this.$children;
  },
  mounted() {
    this.selectTab(0);
  },

  methods: {
    /**
     * method that allows for displaying only one tab at the time
     * @param i pass index of the tab
     */
    selectTab(i) {
      this.selectedIndex = i;
      this.tabs.forEach((tab, index) => {
        tab.isActive = index === i
      })
      this.$root.$emit('tab:ids', this.tabs.length, this.selectedIndex)
    },
    /**
     * method to emit the selected index
     * requires prop value of switchedTab from parent component
     */
    emitSelect() {
      this.selectTab(this.switchedTab)
      this.$emit('addMore', this.selectedIndex)
    },

    emitDestroy() {
      if (this.activeTabs.length > 1) {
        this.destroyed.push(this.selectedIndex)
        this.$root.$emit('tab:destroy', this.destroyed)

        const firstActiveTabIndex = this.tabs.indexOf(this.activeTabs[0])
        this.selectTab(firstActiveTabIndex)
      }
    }
  }
};
</script>
