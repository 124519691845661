<template>
  <div class="my-3 p-4 bg-grey-light border rounded">
    <dt
      class="item flex justify-between"
      :class="{ item__active: visible }"
      @click="open"
    >
      <h5>{{ item.q }}</h5>
      <span class="chevron">&#9002;</span>
    </dt>
    <transition
      name="accordion"
      @enter="startTransition"
      @after-enter="endTransition"
      @before-leave="startTransition"
      @after-leave="endTransition"
    >
      <dd v-if="visible">
        <p v-html="item.a" />
      </dd>
    </transition>
  </div>
</template>

<script>
export default {
  name: "AccordionItem",
  inject: ["accordion"],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      index: null,
    };
  },
  computed: {
    visible() {
      return this.index == this.accordion.active;
    },
  },
  created() {
    this.accordion.count += 1;
    this.index = this.accordion.count;
  },
  methods: {
    open() {
      if (this.visible) {
        this.accordion.active = null;
      } else {
        this.accordion.active = this.index;
      }
    },
    startTransition(el) {
      el.style.height = el.scrollHeight + "px";
    },
    endTransition(el) {
      el.style.height = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  cursor: pointer;
}

dt {
  &.item__active .chevron {
    transform: rotate(270deg);
    transition: transform 0.3s ease;
  }
  .chevron {
    transform: rotate(90deg);
    transition: transform 0.3s ease;
  }
}

.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition: height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
  height: 0 !important; //ensures transition leave-to displays correctly
  opacity: 0;
}
</style>
