<template>
  <div>
    <table class="table-auto space-y-6 mx-auto mb-10">
      <thead>
        <tr>
          <th class="px-4 py-6 text-left">
            <button @click="sort('facility_name')" class="flex font-bold">
              Facility
              <Sort class="w-12 mt-1" />
            </button>
          </th>
          <th class="px-4 py-6 text-left">
            <button @click="sort('species_name')" class="flex font-bold">
              Species
              <Sort class="w-12 mt-1" />
            </button>
          </th>
          <th class="px-4 py-6 text-left" @click="sort('valid_from')">
            <button class="flex font-bold">
              Valid from
              <Sort class="w-12 mt-1" />
            </button>
          </th>
          <th class="px-4 py-6 text-left" @click="sort('valid_to')">
            <button class="flex font-bold">
              Valid to
              <Sort class="w-12 mt-1" />
            </button>
          </th>
          <th class="px-4 py-6 text-left">
            <button @click="sort('country_name')" class="flex font-bold">
              Country
              <Sort class="w-12 mt-1" />
            </button>
          </th>
          <th class="px-4 py-6 text-left">
            <button @click="sort('assessment')" class="flex font-bold">
              Assessment
              <Sort class="w-12 mt-1" />
            </button>
          </th>
          <th class="px-4 py-6 text-left">Documents</th>
          <th class="px-4 py-6 text-left"/>
          <th class="px-4 py-6 text-left"/>
        </tr>
      </thead>
      <tbody v-for="(data, dataIndex) in dataToShow" :key="dataIndex">
        <tr class="mt-6">
          <td class="px-4 py-6 bg-gray-100 border-b-4 border-white">
            {{ data.facility_name }}
          </td>
          <td class="px-4 py-6 bg-gray-100 border-b-4 border-white italic">
            {{ data.species_names.join(', ') }}
          </td>
          <td class="px-4 py-6 bg-gray-100 border-b-4 border-white">
            {{ unixToHumanDate(data.valid_from) }}
          </td>
          <td class="px-4 py-6 bg-gray-100 border-b-4 border-white">
            {{ unixToHumanDate(data.valid_to) }}
          </td>
          <td class="px-4 py-6 bg-gray-100 border-b-4 border-white">
            {{ data.country_names.join(', ') }}
          </td>
          <td class="px-4 py-6 bg-gray-100 border-b-4 border-white">
            {{ assessmentText(data) }}
          </td>
          <td v-if="data.attachments_path" class="px-4 py-6 bg-gray-100 border-b-4 border-white text-center">
            <ModalTrigger
              id="download-modal"
              @open="getModalContent(data.attachments_path)"
            >
              <div class="underline">view</div>
            </ModalTrigger>
          </td>
          <td v-else class="px-4 py-6 bg-gray-100 border-b-4 border-white text-grey text-center">view</td>
          <td class="px-4 py-6 bg-gray-100 border-b-4 border-white">
            <button>
              <a :href="`/facility_decisions/${data.id}/edit`">
                <Edit class="w-6" />
              </a>
            </button>
          </td>
          <td class="px-4 bg-gray-100 border-b-4 border-white">
            <a :href="`/facilities/${data.facility_id}`">
              <RightArrow class="w-12" />
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <ModalTarget
      id="download-modal"
      class="
        fixed
        inset-0
        bg-grey-light bg-opacity-80
        overflow-y-auto
        w-full
        pt-40
        z-10
        h-full
      "
    >
      <DocumentDownloads
        id="download-modal"
        :dataArray="modalDataArray"
        :text="textObject"
        class="bg-white"
      />
    </ModalTarget>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import RightArrow from "../icons/RightArrow";
import Sort from "../icons/Sort";
import Edit from "../icons/Edit";
import ModalTarget from '../modal/ModalTarget.vue';
import ModalTrigger from '../modal/ModalTrigger.vue';
import DocumentDownloads from "../facility/modals/DocumentDownloads.vue";

export default {
  name: "DecisionTable",
  components: {
    DocumentDownloads,
    Edit,
    RightArrow,
    Sort,
    ModalTarget,
    ModalTrigger,
  },
  data() {
    return {
      dataToShow: [],
      urlWithParams: '/facility_decisions.json?sort_by=facility_name',
      order: "",
      modalDataArray: [],
      textObject: {
        back: 'Back',
        title: {
          doc_download: 'Document downloads'
        }
      }
    }
  },
  created() {
    this.getData()
  },
  methods: {
    assessmentText(data) {
      return data.assessment === 'Other' ? data.assessment_text : data.assessment;
    },
    sort(column) {
      if (this.urlWithParams === "") {
        this.urlWithParams = `/facility_decisions.json?sort_by=${column}`;
      } else if (this.urlWithParams === `/facility_decisions.json?sort_by=${column}`)
        this.urlWithParams = `/facility_decisions.json?order=reverse&sort_by=${column}`;
      else {
        this.urlWithParams = `/facility_decisions.json?sort_by=${column}`;
      }
      this.getData();
    },
    unixToHumanDate(timestamp) {
      if (timestamp === null) {
        return "";
      } else {
        return moment.unix(timestamp).format("DD MMM YYYY");
      }
    },
    getData() {
      axios
        .get(this.urlWithParams)
        .then((response) => {
          this.dataToShow = response.data;
        })
        .catch(function (error) {});
    },
    getModalContent(url) {
      axios
        .get(url)
        .then((response) => {
          if (Array.isArray(response.data) && this.id != "update") {
            this.modalDataArray = response.data;
          } else if (this.id != "update") {
            this.modalDataObject = response.data;
          } else {
            this.modalDataObject = JSON.parse(JSON.stringify(response.data));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>