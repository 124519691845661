<template>
  <div
    class="modal z-10"
    :class="{ active: isActive }"
    @click.self="closeModal"
  >
    <div
      role="dialog"
      aria-modal="true"
      class="w-2/5 relative m-auto self-center my-12"
    >
      <div>
        <button :class="closeClasses" @click.prevent="closeModal">
          <Close />
        </button>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import Close from "../icons/Close.vue";

export default {
  name: "ModalTarget",
  components: { Close },
  props: {
    id: {
      type: String,
      required: true,
    },
    closeClasses: {
      type: String,
      required: false,
      default: "absolute top-6 right-6",
    },
  },

  data() {
    return {
      isActive: false,
    };
  },

  computed: {
    triggerId() {
      return "modal-" + this.id;
    },
  },

  created() {
    this.$root.$on(this.triggerId, this.openModal);
    this.$root.$on("close:modal", this.triggerCloseModal);
  },

  methods: {
    openModal() {
      this.isActive = true;
    },

    triggerCloseModal(modalId) {
      if (this.triggerId == modalId) {
        this.closeModal();
      }
    },

    closeModal() {
      this.isActive = false;
    },
  },
};
</script>
