<template>
  <div>
    <Tabs activeClasses="px-5 italic" inertClasses="px-5 italic">
      <Tab
        v-for="(species, speciesIndex) in data"
        :key="speciesIndex"
        :title="species.species_name"
      >
        <h3 class="font-medium my-6">{{ labels.data }}</h3>
        <div
          v-for="(application, applicationIndex) in species.applications"
          :key="applicationIndex"
        >
          <div class="flex items-center justify-between">
            <LargeDot
              v-if="applicationIndex === 0"
              :ref="`application${speciesIndex}`"
              :label="{
                text: 'Applications',
                classesText: 'text-2xl',
                classesDot:
                  'w-5 h-5 ml-1 mr-2 inline-block rounded-full bg-red-200',
              }"
            />
            <div v-if="applicationIndex > 0" />
            <div
              v-if="
                (showApplications === 'Hide previous applications' &&
                  applicationIndex > 1) ||
                applicationIndex === 0
              "
              class="flex items-center"
            >
            </div>
          </div>
          <CardFacilityPage
            id="application"
            :application="application"
            :applicationIndex="applicationIndex"
            :speciesIndex="speciesIndex"
            :labels="labels"
            :species="species"
            :localeModal="localeModal"
            columns="3"
            :index="applicationIndex"
            class="mb-2 -mt-16"
            :class="{
              hidden:
                applicationIndex > 0 &&
                showApplications === 'View previous applications',
            }"
          />
          <div v-if="viewPreviousApplication(applicationIndex, species.applications)" class="flex justify-between">
            <p class="text-grey underline my-6" @click="toggleShowApplications">
              {{ showApplications }}
            </p>
          </div>
        </div>
        <LargeDot
          :ref="`facility${speciesIndex}`"
          :label="{
            text: 'Details for species',
            classesText: 'text-2xl',
            classesDot: 'w-5 h-5 ml-1 mr-2 mt-12 inline-block rounded-full bg-green',
          }"
        />
        <CardFacilityPage
          :labels="labels"
          :species="species"
          :speciesIndex="speciesIndex"
          :localeModal="localeModal"
          columns="4"
          class="mb-2"
        />
      </Tab>
    </Tabs>
  </div>
</template>

<script>
import CardFacilityPage from "./CardFacilityPage.vue";
import LargeDot from "../atoms/LargeDot.vue";
import Tabs from "../tabs/Tabs.vue";
import Tab from "../tabs/Tab.vue";

export default {
  name: "CardTabs",
  components: {
    CardFacilityPage,
    LargeDot,
    Tabs,
    Tab,
  },

  props: {
    labels: {
      type: Object,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    localeModal: {
      type: Object,
      required: true,
    },
    jumpTo: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      showApplications: "View previous applications",
      application: "application0",
      facility: "facility0",
    };
  },

  created() {
    this.$root.$on("tab:ids", this.setReference);
    this.$root.$on("jump", this.goTo);
  },

  methods: {
    toggleShowApplications() {
      this.showApplications =
        this.showApplications === "View previous applications"
          ? "Hide previous applications"
          : "View previous applications";
    },
    setReference(x, value) {
      this.application = `application${value}`;
      this.facility = `facility${value}`;
    },
    goTo(item) {
      if (item == 0) {
        this.$refs[this.application][0].$el.scrollIntoView({ behavior: "smooth" });
      } else {
        this.$refs[this.facility][0].$el.scrollIntoView({ behavior: "smooth" });
      }
    },
    viewPreviousApplication(applicationIndex, applications) {
      return applicationIndex === 0 && applications.length > 1
    }
  },
};
</script>
