<template>
  <div>
    <div class="mx-96 py-site lg:p-site">
      <h2 class="pb-6">{{ formLocale.heading }}</h2>
      <p class="pb-6 space-y-4" v-html="formLocale.intro" />
      <FormWrapper 
        :formData="formData"
      />
    </div>
  </div>
</template>
<script>
import FormWrapper from "../forms/FormWrapper"
import { Endpoints } from "../../constants/constants.js"

export default {
  name: "CondensedForm",
  components: {
    FormWrapper
  },
  props: {
    arrowImg: {
      type: String,
      required: false
    },
    formData: {
      type: Object
    },
    formLocale: {
      type: Object,
      required: true
    },
    placeholder: {
      type: Object,
      required: false
    },
    placeholderAddress: {
      type: String,
      required: false
    },
  },
  data() {
    return {
      searchIds: {
        country: "",
        taxa: ""
      },
      searchStrings: {
        country: "",
        taxa: ""
      },
      facilityDetails: {},
      endpoints: Endpoints,
      classes: {
        description: "text-grey mb-6 mt-2",
        h3: "font-medium mt-12 mb-6"
      }
    }
  },
  computed: {
    addressDisplay() {
      return this.facilityDetails.address
        ? this.facilityDetails.address.replaceAll(",", "<br>")
        : "";
    },
  },
  methods: {
    countryInputSelect(value) {
      this.searchIds.country = value.id || "";
      this.searchStrings.country = value.name;
    },
    disableSubmit(value) {
      this.autocompleteOpen = value;
    },
    showDetails(value) {
      this.facilityDetails = value;
    },
    // need a method that will duplicate the tab
    addSpecies(value) {
      this.species.push(value);
    },
    newTab(value) {
      //needs a function to validate the input
      this.species.push(this.species.length + 1);
      this.currentTab = value;
    },
  },
};
</script>
