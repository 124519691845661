<template>
  <div v-show="hideLabel !== fieldName">
    <label :for="title">{{ title }}</label>
    <span v-if="required" class="text-danger text-2xl px-1">&#42;</span>
    <p v-if="description" class="text-grey mb-6 mt-2">{{ description }}</p>
  </div>
</template>
<script>
export default {
  title: "FormLabel",
  props: {
    fieldName: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false
    },
    classes: {
      type: String,
      required: false
    },
    hideLabel: {
      type: String,
      required: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    }
  },
};
</script>
