<template>
  <div>
    <a class="mx-site pt-10 inline-block text-xl underline" :href="this.backPath">
      Back to search
    </a>
    <div class="flex mx-site pb-3">
      <DynamicHeading title="Search results" :data="searchValues" />
      <DropdownFilter
        class="ml-auto"
        :options="dropdownOptions"
        @selected="sortCards"
      />
    </div>
    <p class="mx-site pb-10 text-grey font-thin">{{ totalResults }} results</p>
    <card-grid v-bind="{ calendarIcon, lastUpdatedText, results }" />
  </div>
</template>

<script>
import CardGrid from "../card/CardGrid"
import DynamicHeading from "../heading/DynamicHeading"
import { dynamicSort } from "../../helpers/sorting.js"
import DropdownFilter from "../dropdown/DropdownFilter.vue"

export default {
  name: "SearchResults",
  components: {
    CardGrid,
    DynamicHeading,
    DropdownFilter
  },
  props: {
    calendarIcon: {
      type: String,
      required: true
    },
    dropdownOptions: {
      type: Array,
      required: true
    },
    lastUpdatedText: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      results: [],
      searchValues: {}
    }
  },
  computed: {
    backPath() {
      let backPath = "search";

      const taxaSearchTerm = this.searchValues.taxa
      const countrySearchTerm = this.searchValues.country
      if (taxaSearchTerm && countrySearchTerm) {
        backPath += `?taxa=${taxaSearchTerm}&country=${countrySearchTerm}`;
      }

      return backPath
    },
    totalResults() {
      return this.results.length
    },
  },
  // get the value from dropdown $emit and check if the value is the same as current value somehow
  created() {
    // here we gonna pre-order the results based on the dropdown emit
    if (localStorage.getItem("searchResults")) {
      this.results = JSON.parse(localStorage.getItem("searchResults"))
      this.searchValues = JSON.parse(localStorage.getItem("searchValues"))
    }
  },
  methods: {
    sortCards(value) {
      value == "alphabetical"
        ? (this.results = this.results.sort(dynamicSort("full_name", 1)))
        : (this.results = this.results.sort(
            dynamicSort("last_updated_at", -1)
          ))
    },
  }
}
</script>
