<template>
  <div class="bg-grey-light py-6 pl-6 border-2 border-grey-medium rounded">
    <p class="text-4xl pb-4">
      {{ title }}
      <span class="text-base text-blue pl-4"
        >{{ step }} of {{ stepsNumber }}</span
      >
    </p>
    <p class="text-lg pb-2">{{ content.bodyTitle }}</p>
    <ul class="text-lg list-inside list-disc">
      <li v-for="(bullet, bulletIndex) in content.bullets" :key="bulletIndex">
        {{ bullet }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "StepBox",

  props: {
    title: {
      required: true,
      type: String,
    },
    step: {
      required: true,
      type: Number,
    },
    content: {
      required: true,
      type: Object,
    },
    stepsNumber: {
      required: true,
      type: Number,
    },
  },
};
</script>
