<template>
  <div>
    <ModalTrigger
      v-if="id == 'decision-1' || id == 'decision-2'"
      @open="getModalContent(url)"
      :id="id"
    >
      <RoundButtonNoRedirect
        class="ml-2"
        height="h-7"
        width="w-7"
      />
    </ModalTrigger>
    <ModalTrigger
      v-else-if="modalHasData"
      @open="getModalContent(url)"
      :id="id"
      class="underline"
      :class="classes"
    >
      {{ componentToInsert.description }}
    </ModalTrigger>
    <p v-else class="text-grey">view</p>
    <ModalTarget
      :id="id"
      class="
        fixed
        inset-0
        bg-grey-light bg-opacity-80
        overflow-y-auto
        w-full
        pt-40
        z-10
        h-full
      "
    >
      <component
        v-if="componentToInsert"
        :is="componentToInsert.name"
        :dataArray="modalDataArray"
        :dataObject="modalDataObject"
        :facilityDetails="facilityDetails"
        :activeDecision="activeDecision"
        :updatedAt="updatedAt"
        :text="localeModal"
        :id="id"
        :title="componentToInsert.title"
        class="bg-white"
        :redirectPath="redirectPath"
      />
    </ModalTarget>
  </div>
</template>

<script>
import ModalTarget from "../../modal/ModalTarget.vue";
import ModalTrigger from "../../modal/ModalTrigger.vue";
import DocumentDownloads from "./DocumentDownloads.vue";
import FacilityDetails from "./FacilityDetails.vue";
import ChangesOverTime from "./ChangesOverTime.vue";
import DecisionModal from "./DecisionModal.vue";
import UpdateFacilityDetails from "./UpdateFacilityDetails.vue";
import RoundButtonNoRedirect from "../../button/RoundButtonNoRedirect.vue";

import axios from "axios";

export default {
  name: "Modal",
  components: {
    ModalTarget,
    ModalTrigger,
    ChangesOverTime,
    DocumentDownloads,
    FacilityDetails,
    DecisionModal,
    UpdateFacilityDetails,
    RoundButtonNoRedirect,
  },

  props: {
    url: {
      required: false,
      type: String,
    },
    id: {
      required: true,
      type: String,
    },
    speciesIndex: {
      type: Number,
      required: false,
    },
    applicationIndex: {
      type: Number,
      required: false,
    },
    localeModal: {
      required: false,
      type: Object,
    },
    facilityDetails: {
      required: false,
      type: Object,
    },
    textSize: {
      type: String,
      default: "text-sm",
    },
    textColor: {
      type: String,
      default: "text-blue",
    },
    updatedAt: {
      type: Number,
      required: false,
    },
    activeDecision: {
      required: false,
      type: Boolean,
    },
    redirectPath: {
      required: false,
      type: String,
    }
  },

  data() {
    return {
      modalDataObject: {},
      modalDataArray: [],
    };
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    componentToInsert() {
      switch (this.id) {
        case `founder stock-${this.speciesIndex}`:
          return {
            name: "changes-over-time",
            description: "see previous data",
            title: "founder stock",
          }
        case `total no. of specimens-${this.speciesIndex}`:
          return {
            name: "changes-over-time",
            description: "see changes over time",
            title: "total no. of specimens",
          }
        case `offspring produced per year-${this.speciesIndex}`:
          return {
            name: "changes-over-time",
            description: "see changes over time",
            title: "offspring produced per year",
          }
        case `download-species${this.speciesIndex}-application${this.applicationIndex}`:
          return { 
            name: "document-downloads", 
            description: "view" 
          }
        case "facility details":
          return {
            name: "facility-details",
            description: "See details",
          }
        case "decision-1":
          return {
            name: "decision-modal",
            content: "facility"
          }
        case "decision-2":
          return {
            name: "decision-modal",
            content: "srg"
          }
        case "update":
          return {
            name: "update-facility-details",
            description: "update",
          }
      }
    },
    classes() {
      return `${this.textSize} ${this.textColor}`
    },
    modalHasData() {
      return (
        this.modalDataArray.length > 0 ||
        Object.keys(this.modalDataObject).length > 0 ||
        this.facilityDetails
      )
    },
  },

  created() {
    this.getModalContent(this.url)
  },

  methods: {
    getModalContent(url) {
      if (url) {
        axios
          .get(url)
          .then((response) => {
            if (Array.isArray(response.data) && this.id != "update") {
              this.modalDataArray = response.data
            } else if (this.id != "update") {
              this.modalDataObject = response.data
            } else {
              this.modalDataObject = JSON.parse(JSON.stringify(response.data))
            }
          })
          .catch(function (error) {
            console.log(error)
          })
      }
    },
  },
}
</script>
