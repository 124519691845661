<template>
  <a class="block" :href="facilitySpeciesStockPath">
    <div class="h-9 py-2 px-6 flex justify-center text-xs bg-grey-medium rounded-full underline">
      <component
        v-bind:is="rowFacilitySpeciesIcon(warningType)"
        class="w-6 pr-2"
      />
      {{ label }}
    </div>
  </a>
</template>
<script>
import Warning from "../icons/Warning";
import Edit from "../icons/Edit";

export default {
  name: "DynamicEditButton",
  components: {
    Warning,
    Edit,
  },
  props: {
    warningType: {
      type: String,
      required: true,
    },
    facilitySpeciesStockPath: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  methods: {
    rowFacilitySpeciesIcon(type){
      if(type === 'add') {
        return 'Warning'
      } else {
        return 'Edit'
      }
    },
  }
}
</script>