<template>
  <div :class="classes.wrapper">
    <div class="h-12 font-medium">
      <span v-if="type">{{ type.toUpperCase() }}</span>
      <span class="mx-2 text-grey">&#8226;</span>
      <span v-if="card.country">{{ card.country.toUpperCase() }}</span>
    </div>
    <h3 v-if="card.full_name" class="mt-8 mb-14 font-medium">
      {{ card.full_name }}
    </h3>
    <div class="flex items-center justify-between">
      <div class="max-w-2/3">
        <span
          class="h-4 w-4 mr-1 inline-block position-bg"
          :style="{ backgroundImage: `url('${calendarIcon}')` }"
        />
        <span>
          {{ lastUpdatedAt }}
        </span>
      </div>
      <a :href="`/facilities/${card.id}`">
        <RightArrow class="row-span-2" :link="`/facilities/${card.id}`" />
      </a>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import RightArrow from "../icons/RightArrow";
export default {
  name: "Card",
  components: { RightArrow },
  props: {
    card: {
      type: Object,
      required: true,
    },
    calendarIcon: {
      type: String,
      required: true,
    },
    lastUpdatedText: {
      type: String,
      required: true
    },
    rows: {
      type: Object,
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: "Facility",
    },
  },
  data() {
    return {
      classes: {
        wrapper:
          "min-h-36 px-10 py-5 border bg-grey-light rounded hover:border-transparent hover:shadow-card grid grid-rows-auto",
      }
    };
  },
  computed: {
    lastUpdatedAt() {
      return `${this.lastUpdatedText} ${this.unixToHumanDate(this.card.last_updated_at)}`
    }
  },
  methods: {
    unixToHumanDate(timestamp) {
      return moment.unix(timestamp).format("DD MMM YYYY");
    },
  },
};
</script>