<template>
  <div class="autocomplete">
    <form>
      <div class="p-3 border flex justify-between rounded bg-white">
        <div class="w-full" :class="{ 'border-r-grey': this.dropdown }">
          <p v-if="heading">
            <b>
              <label
                :for="`autocomplete-${name}`"
                class="cursor-pointer text-base"
                v-text="heading"
              />
            </b>
          </p>
          <input
            :id="`autocomplete-${name}`"
            class="w-full placeholder-grey focus:outline-none text-lg"
            v-model="search"
            :placeholder="placeholder"
            @input="onChange()"
            @keydown.down.prevent="onArrowDown"
            @keydown.up.prevent="onArrowUp"
            @keydown.enter.prevent="setResult(keyResult)"
            type="text"
          >
        </div>
        <span
          class="inline-block border-left ml-2 h-2 w-4 self-center position-bg"
          v-if="dropdown"
          @click="toggleDropdown"
          :style="{ backgroundImage: `url('${arrow}')` }"
        />
      </div>
      <ul
        class="relative z-10 border max-h-64 p-3 bg-white overflow-auto"
        v-show="isOpen"
      >
        <li v-if="inputTooShort">
          Please type at least {{ config.characterStart }} characters
        </li>
        <li v-else-if="resultsLengthZero">No results</li>
        <li
          v-else
          v-for="(result, key) in results"
          :key="key"
          :result="result[config.searchKey]"
          @click="setResult(result)"
          :class="{ 'is-active': key === arrowCounter }"
          v-html="listItemText(result)"
        />
      </ul>
    </form>
  </div>
</template>

<script>
import axios from "axios"

export default {
  name: "Autocomplete",
  props: {
    arrow: {
      type: String,
      required: false
    },
    config: {
      type: Object,
      required: true
    },
    dropdown: {
      type: Boolean,
      default: false
    },
    heading: {
      type: String,
      default: undefined
    },
    name: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: "...",
    },
    inTab: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: Object,
    },
  },

  data() {
    return {
      search: "",
      results: [],
      isOpen: false,
      arrowCounter: -1,
      items: [],
      hasSelectedResult: false
    }
  },

  computed: {
    inputTooShort() {
      return this.search.length < this.config.characterStart
    },
    resultsLengthZero() {
      return this.results.length === 0
    },
    keyResult() {
      return this.results[this.arrowCounter]
    },
    endpointWithParams() {
      return `${this.config.baseUrl}?${this.config.param}=${this.search}`
    },
    urlParam() {
      const urlParams = {}
      const query = window.location.search
      const urlParamsList = query.replace("?", "").split("&")
      urlParamsList.forEach((urlParam) => {
        const keyValue = urlParam.split("=")
        urlParams[keyValue[0]] = decodeURI(keyValue[1])
      })
      return urlParams[this.name]
    }
  },

  watch: {
    items: function (value, oldValue) {
      if (value.length !== oldValue.length) {
        this.results = value
        this.isLoading = false

        if (this.urlParam) {
          const result = this.results.find(result => result[this.config.searchKey] === this.search)
          this.setResult(result);
          this.hasSelectedResult = true;
        }
      }
    },
    isOpen: function (value, oldValue) {
      if (value !== oldValue) {
        this.$emit("isopen", value)
      }
    }
  },

  created() {
    this.fetchOnStart();
    if (this.urlParam) {
      this.search = this.urlParam;
      if (!this.config.callOnce) {
        this.callbackAxios(this.config.endpoint || this.endpointWithParams)
      }
    }

    if(this.value) {
      this.setResult(this.value)
    }
  },

  mounted() {
    document.addEventListener("click", this.handleClickOutside)
  },

  destroyed() {
    document.removeEventListener("click", this.handleClickOutside)
  },

  methods: {
    callbackAxios(url) {
      axios
        .get(url)
        .then((response) => {
          this.items = response.data
        })
        .catch((error) => {
          console.log(error.response)
        })
    },
    filterResults() {
      this.results = this.items.filter((item) => {
        return (
          item[this.config.searchKey]
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) > -1
        )
      })
    },
    fetchOnStart() {
      if (this.config.callOnce) {
        this.callbackAxios(this.config.endpoint || this.endpointWithParams)
      }
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.arrowCounter = -1
        this.isOpen = false
        if (!this.hasSelectedResult) {
          this.search = "";
          this.$emit("update", { name: this.name, input: "", result: "" , inTab: this.inTab })
        }
      }
    },
    listItemText(result) {
      let text = result[this.config.searchKey];

      if (this.config.showMatching) {
        const matchingNames = result.matching_names;

        if (matchingNames.length > 0) {
          const matchingNamesString = matchingNames.join(", ");
          text += ` (${matchingNamesString})`;
        }
      }

      const formattedText = text.replace(
        new RegExp(`(${this.search})`, "gi"),
        '<span class="underline">$1</span>'
      );

      return formattedText;
    },
    onChange() {
      if (!this.config.callOnce && !this.inputTooShort) {
        this.callbackAxios(this.config.endpoint || this.endpointWithParams)
      } else if (this.config.callOnce) {
        this.filterResults()
      } else {
        this.$emit("update", { name: this.name, input: "", result: "" , inTab: this.inTab })
      }
      this.isOpen = true
      this.hasSelectedResult = false
    },
    onArrowUp() {
      this.arrowCounter > 0
        ? (this.arrowCounter = this.arrowCounter - 1)
        : (this.arrowCounter = this.results.length - 1)
    },
    onArrowDown() {
      this.arrowCounter < this.results.length
        ? (this.arrowCounter = this.arrowCounter + 1)
        : (this.arrowCounter = 0)
    },
    setResult(result) {
      if (result) {
        this.hasSelectedResult = true
        this.search = result[this.config.searchKey]
        this.isOpen = false
        this.submitItem(result)
      }
    },
    submitItem(result) {
      let input

      //TODO find a way that we don't have to hardcode the input in this way
      if(this.name == 'facility_id') {
        input = result.id
      } else {
        input = {
          id: result.id, 
          postData: {
            "species_plus_id": result.species_plus_id || result.id, 
            "name": result[this.config.searchKey]
          }
        }
      }
      this.$emit('update', { name: this.name, input: input, result: result, inTab: this.inTab })
    },
    toggleDropdown() {
      this.isOpen ? (this.isOpen = false) : (this.isOpen = true)
    }
  }
}
</script>
