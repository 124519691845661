<template>
  <div class="my-6 flex justify-between" v-if="detailsPresent">
    <label :for="type">{{ label }}</label>
    <div class="w-1/2">
      <p v-for="(detail, index) in showDetails" :key="index">{{ detail }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "ToggleDetails",
  props: {
    showDetails: {
      type: [String, Array],
      required: false,
    },
    label: {
      type: String,
      required: false
    },
    type: {
      type: String,
    },
  },
  computed: {
    detailsPresent() {
      return this.showDetails.length > 0
    }
  }
};
</script>
