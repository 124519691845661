<template>
  <div class="px-10 py-14">
    <h3 class="mb-4">
      {{ text.title.doc_download }}
    </h3>
    <table class="table-auto w-full mb-6 text-left">
      <thead>
        <th>Name</th>
        <th>Uploaded on</th>
        <th>Download</th>
      </thead>
      <tbody v-for="(data, dataIndex) in dataArray" :key="dataIndex">
        <tr class="border-b-grey">
          <td class="font-medium">{{ data.filename }}</td>
          <td class="font-medium">
            {{ unixToHumanDate(data.last_updated_at) }}
          </td>
          <td>
            <button>
              <a :href="data.url" @click.prevent="downloadItem(data)">
                <Download class="h-6 w-6 m-3" />
              </a>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <button class="w-24 h-10 bg-blue text-white" @click="closeModal">
      {{ text.back }}
    </button>
  </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import Download from '../../icons/Download';
import { setAxiosHeaders } from '../../../helpers/axios';

export default {
  name: 'DocumentDownloads',
  components: {
    Download,
  },

  props: {
    dataArray: {
      required: true,
      type: Array,
    },
    text: {
      required: true,
      type: Object,
    },
    id: {
      required: true,
      type: String,
    },
  },

  mounted() {
    setAxiosHeaders(axios);
  },

  methods: {
    unixToHumanDate(timestamp) {
      return moment.unix(timestamp).format('DD/MM/YYYY');
    },
    downloadItem({ url, filename }) {
      axios
        .get(url, { responseType: 'blob' })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/png' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    closeModal() {
      this.$root.$emit('close:modal', `modal-${this.id}`);
    },
  },
};
</script>
