<template>
  <div>
    <input :name="nameSpeciesPlusId" :value="valueSpeciesPlusId" type="hidden">
    <input :name="nameName" :value="valueNameData" type="hidden">
    <Autocomplete
      :config="config"
      :baseUrl="baseUrl"
      :param="param"
      :placeholder="placeholder"
      :searchKey="searchKey"
      @update="updateValues"
      :value="value"
      :name="name"
    />
  </div>
</template>

<script>
import Autocomplete from "../autocomplete/Autocomplete";

export default {
  name: "RailsFormWrapper",
  components: {
    Autocomplete,
  },
  props: {
    nameSpeciesPlusId: {
      type: String,
      required: true,
    },
    nameName: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    baseUrl: {
      type: String,
      default: undefined,
    },
    endpoint: {
      type: String,
      default: undefined,
    },
    param: {
      type: String,
      default: undefined,
    },
    searchKey: {
      type: String,
      required: true,
    },
    showMatching: {
      type: Boolean,
      default: false,
    },
    callOnce: {
      type: Boolean,
      default: false,
    },
    characterStart: {
      type: Number,
      default: 0
    },
    value: {
      type: Object,
    },
  },

  data() {
    return {
      name: this.nameName,
      valueNameData: "",
      valueSpeciesPlusId: "",
      config: {
        baseUrl: this.baseUrl,
        characterStart: this.characterStart,
        endpoint: this.endpoint,
        param: this.param,
        searchKey: this.searchKey,
        showMatching: this.showMatching,
        callOnce: this.callOnce,
      },
    };
  },

  created() {
    this.valueNameData = this.value.full_name;
    this.valueSpeciesPlusId = this.value.id;
  },

  methods: {
    updateValues(result) {
      this.valueNameData = result.result[this.searchKey];
      this.valueSpeciesPlusId = result.result.id;
    },
  },
};
</script>
