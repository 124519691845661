<template>
  <div>
    <div v-if="id === 'application'">
      <div class="grid justify-items-end mb-6">
        <ModalTrigger
          :id="`application-${application.id}`"
          class="underline"
        >
          <div class="flex">
            <Chat class="w-9 h-9 pr-2"/>
            <span class="underline mr-4">{{ labels.comments_link }}</span>
          </div>
        </ModalTrigger>
        <ModalTarget
          :id="`application-${application.id}`"
          class="
            fixed
            inset-0
            bg-grey-light bg-opacity-80
            overflow-y-auto
            w-full
            pt-40
            z-10
            h-full
          "
        >
          <ApplicationPostForm
            class="bg-white"
            :id="`application-${application.id}`"
            :applicationPostPath="application.application_post_path"
            :applicationForumThreadName="`${
              species.species_name
            } - ${ unixToHumanDate(application.date_uploaded) }`"
          />
        </ModalTarget>
      </div>
      <div class="min-h-20 bg-grey-xlight py-2 px-1" :class="classes">
        <CardColumn>
          <div>
            <p class="font-medium">
              {{ labels.applications.heading_col_1.label_1 }}
            </p>
            <h3 class="text-3xl text-grey pb-5">
              {{ application.importing_member_state_name }}
            </h3>
          </div>
          <div v-if="expandAccordion(applicationIndex)">
            <p class="font-medium pb-2">
              {{ labels.applications.heading_col_1.label_2 }}
            </p>
            <div class="pb-2">
              {{ application.decision_details }}
            </div>
            <ul
              v-for="(reason, reasonIndex) in application.decision_reasons"
              :key="reasonIndex"
            >
              <li>• {{ reason }}</li>
            </ul>
          </div>
        </CardColumn>
        <CardColumn>
          <div>
            <p class="font-medium">
              {{ labels.applications.heading_col_2.label_1 }}
            </p>
            <div>
              {{ unixToHumanDate(application.date_uploaded) }}
            </div>
          </div>
          <div>
            <p class="font-medium">
              {{ labels.applications.heading_col_2.label_3 }}
            </p>
            <div>
              {{ application.source.code }}
            </div>
          </div>
          <div v-if="expandAccordion(applicationIndex)">
            <p class="font-medium">
              {{ labels.applications.heading_col_2.label_2 }}
            </p>
            <Modal
              :url="`${application.document_downloads_path}.json`"
              :id="idMaker"
              textColor="text-black"
              textSize="text-lg"
              :localeModal="localeModal"
              :applicationIndex="applicationIndex"
              :speciesIndex="speciesIndex"
            />
          </div>
        </CardColumn>
        <CardColumn>
          <div>
            <p class="font-medium">
              {{ labels.applications.heading_col_3.label_1 }}
            </p>
            <div class="capitalize">
              {{ application.application_decision }}
            </div>
          </div>
          <div v-if="expandAccordion(applicationIndex)" class="h-20">
            <p class="font-medium">
              {{ labels.applications.heading_col_3.label_2 }}
            </p>
            <div v-text="quantityString(application)" />
          </div>
        </CardColumn>
      </div>
      <div
        v-if="index > 0"
        class="flex bg-blue text-white pl-4 mb-2 items-center"
        @click="onClick"
      >
        <div>
          {{ showViewMoreOrLess }}
        </div>
        <div v-if="!showAccordion" class="pl-2 text-xs">
          <ArrowDown class="w-3" />
        </div>
        <div v-else class="pl-2 text-xs">
          <div class="transform rotate-180"><ArrowDown class="w-3" /></div>
        </div>
      </div>
    </div>
    <div v-else class="min-h-20 bg-grey-xlight py-2 px-1" :class="classes">
      <CardColumn>
        <div class="h-20">
          <div class="font-medium">
            {{ labels.facility_details_species.heading_col_1.label_1 }}
          </div>
          <div class="italic">
            {{ species.species_name }}
          </div>
        </div>
        <div>
          <div class="font-medium">
            {{ labels.facility_details_species.heading_col_1.label_2 }}
          </div>
          <div class="flex flex-col xl:flex-row items-center xl:items-end">
            <p class="leading-tight">
              {{ species.founder_stock_total }}
            </p>
            <p class="text-grey pl-2 pr-1 text-sm leading-1">
              {{ species.founder_stock_total_date_acquired }}
              <span class="px-1">•</span>
            </p>
            <Modal
              :url="species.founder_stock_history_path"
              :id="`founder stock-${speciesIndex}`"
              :localeModal="localeModal"
              :speciesIndex="speciesIndex"
              :redirectPath="species.facility_species_stock_path"
            />
          </div>
        </div>
      </CardColumn>
      <CardColumn>
        <div class="h-20">
          <div class="font-medium">
            {{ labels.facility_details_species.heading_col_2.label_1 }}
          </div>
          <div class="italic">
            {{ decisionValue }}
          </div>
        </div>
        <div>
          <div class="font-medium">
            {{ labels.facility_details_species.heading_col_2.label_2 }}
          </div>
          <div class="flex flex-col xl:flex-row items-center xl:items-end self-center">
            <p class="leading-tight">
              {{ species.current_stock_total }}
            </p>
            <p class="text-grey pl-2 pr-1 text-sm leading-1">
              {{ species.founder_stock_total_date_acquired }}
              <span class="px-1">•</span>
            </p>
            <Modal
              :url="species.current_stock_history_path"
              :id="`total no. of specimens-${speciesIndex}`"
              :localeModal="localeModal"
              :speciesIndex="speciesIndex"
              :redirectPath="species.facility_species_stock_path"
            />
          </div>
        </div>
      </CardColumn>
      <CardColumn>
        <div class="h-20">
          <div class="font-medium">
            {{ labels.facility_details_species.heading_col_3.label_1 }}
          </div>
          <div>
            {{ species.annual_production_mortality_rate }}
          </div>
        </div>
        <div>
          <div class="font-medium">
            {{ labels.facility_details_species.heading_col_3.label_2 }}
          </div>
          <div class="flex flex-col xl:flex-row items-center xl:items-end">
            <p class="leading-tight">
              {{ species.annual_production_number }}
            </p>
            <p class="text-grey pl-2 pr-1 text-sm leading-1">
              {{ species.founder_stock_total_date_acquired }}
              <span class="px-1">•</span>
            </p>
            <Modal
              :url="species.annual_production_path"
              :id="`offspring produced per year-${speciesIndex}`"
              :localeModal="localeModal"
              :speciesIndex="speciesIndex"
              :redirectPath="species.facility_species_stock_path"
            />
          </div>
        </div>
      </CardColumn>
      <CardColumn>
        <div class="h-20">
          <div class="font-medium">
            {{ labels.facility_details_species.heading_col_4.label_1 }}
          </div>
          <div>
            {{ applicationSourceCodesText }}
          </div>
        </div>
        <div class="h-20">
          <DynamicEditButton
            class="absolute mt-4"
            :label="labels.facility_species_stock[species.facility_species_stock_warning_type]"
            :warningType="species.facility_species_stock_warning_type"
            :facilitySpeciesStockPath="species.facility_species_stock_path"
          />
        </div>
      </CardColumn>
    </div>
  </div>
</template>

<script>
import ArrowDown from '../icons/ArrowDown.vue'
import CardColumn from './CardColumn.vue'
import DynamicEditButton from "../facility_species_stocks/DynamicEditButton"
import Modal from '../facility/modals/Modal.vue'
import moment from 'moment'
import ModalTarget from "../modal/ModalTarget.vue"
import ModalTrigger from "../modal/ModalTrigger.vue"
import Chat from "../icons/Chat"
import ApplicationPostForm from "../facility/modals/ApplicationPostForm.vue"

export default {
  name: 'CardFacilityPage',
  components: {
    ApplicationPostForm,
    ArrowDown,
    CardColumn,
    DynamicEditButton,
    Modal,
    Chat,
    ModalTarget,
    ModalTrigger,
  },
  props: {
    id: {
      type: String,
      default: 'facility',
    },
    columns: {
      type: String,
      default: '4',
    },
    index: {
      type: Number,
      default: 1,
    },
    application: {
      type: Object,
      required: false,
    },
    species: {
      type: Object,
      required: true,
    },
    applicationIndex: {
      type: Number,
      required: false,
    },
    speciesIndex: {
      type: Number,
      required: false,
    },
    labels: {
      type: Object,
      required: true,
    },
    localeModal: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      cols: {
        3: 'grid grid-cols-2/1/1 ',
        4: 'grid grid-cols-4',
      },
      decisionValue: String,
      showViewMoreOrLess: 'View more',
      showAccordion: false,
    }
  },

  computed: {
    applicationSourceCodesText() {
      return this.species.source_codes.join(', ')
    },
    classes() {
      return this.cols[this.columns]
    },
    idMaker() {
      return `download-species${this.speciesIndex}-application${this.applicationIndex}`
    },
  },

  created(){
    this.$root.$on('current-decision', this.setSrgDecision)
  },

  methods: {
    unixToHumanDate(timestamp) {
      return moment.unix(timestamp).format('DD/MM/YYYY')
    },

    toggleShowApplications() {
      this.showApplications =
        this.showApplications === 'View previous applications'
          ? 'Hide previous applications'
          : 'View previous applications'
    },

    expandAccordion(index) {
      return index === 0 || this.showAccordion
    },

    onClick() {
      this.showViewMoreOrLess =
        this.showViewMoreOrLess === 'View more' ? 'View less' : 'View more';
      this.showAccordion = !this.showAccordion
    },

    setSrgDecision(decisions) {
      const decisionForCurrentSpecies = decisions.find(
        (decision) =>
          decision.taxon_concept.full_name === this.species.species_name
      )

      if (decisionForCurrentSpecies) {
        this.decisionValue = decisionForCurrentSpecies.eu_decision_type.name
      } else {
        this.decisionValue = 'No decision'
      }
    },

    quantityString(application) {
      if (application.unit.name === 'Number of specimens') {
        return `${application.quantity} ${application.term.name}`
      } else {
        return `${application.quantity} ${application.unit.name} ${application.term.name}`
      }
    }
  },
}
</script>
