<template>
  <div>
    <div
      class="py-24 px-96 max-h-100 overlay position-bg"
      :style="{ backgroundImage: `url('${bgImage}')` }"
    >
      <h2 class="mb-2">{{ header }}</h2>
      <Tabs activeClasses="mr-5" inertClasses="mr-5">
        <Tab
          :title="tabs.taxonCountry.title"
          :subtitle="tabs.taxonCountry.subtitle"
          classes="my-6 grid grid-cols-2/2/1 grid-cols-ie11-2-2-1 gap-4 items-start"
        >
          <Autocomplete
            :config="{
              baseUrl: endpoints.TAXON_SEARCH_URL,
              characterStart: 3,
              param: endpoints.TAXON_SEARCH_PARAM,
              searchKey: 'full_name',
              showMatching: true,
            }"
            :heading="inputHeadings.taxon"
            :placeholder="placeholder.taxon"
            @isopen="disableSubmit"
            @update="taxaInputSelect"
            name="taxa"
          />
          <Autocomplete
            :config="{
              characterStart: 0,
              endpoint: endpoints.COUNTRY_SEARCH_URL,
              searchKey: 'name',
              callOnce: true,
            }"
            dropdown
            :heading="inputHeadings.country"
            :placeholder="placeholder.country"
            @update="countryInputSelect"
            @isopen="disableSubmit"
            :arrow="arrowImg"
            name="country"
          />
          <SubmitButton
            textColor="text-white"
            bgColor="bg-blue"
            @submit="submit"
            :buttonText="buttonText"
            :disabled="autocompleteOpen"
          />
        </Tab>
        <Tab
          classes="my-6"
          :title="tabs.facility.title"
          :subtitle="tabs.facility.subtitle"
        >
          <Autocomplete
            :config="{
              characterStart: 0,
              baseUrl: endpoints.FACILITY_SEARCH_URL,
              param: endpoints.FACILITES_SEARCH_PARAM,
              searchKey: 'full_name',
            }"
            :placeholder="placeholder.facility"
            @update="autosubmit"
            :arrow="arrowImg"
            name="facility"
          />
        </Tab>
      </Tabs>
    </div>
    <div class="pl-site mt-14 mb-6">
      <div class="flex justify-between items-center">
        <p class="text-4xl font-medium">{{ carouselContent.title }}</p>
        <a class="underline mr-6 pr-site" :href="'/forum/threads'">{{
          carouselContent.link
        }}</a>
      </div>
    </div>
    <Carousel class="mb-32 pl-site" :key="carouselKey">
      <Item
        v-for="(item, itemIndex) in itemsForCarousel"
        :key="itemIndex"
        :title="item.name"
        :image="item.image_url"
        :buttonText="carouselContent.button"
        :forumCategoryUrl="item.forum_category_url"
      />
    </Carousel>
  </div>
</template>

<script>
import axios from "axios";
import Turbolinks from "turbolinks";
import Autocomplete from "../autocomplete/Autocomplete";
import Carousel from "../carousel/Carousel";
import Item from "../carousel/Item";
import SubmitButton from "../button/SubmitButton";
import Tab from "../tabs/Tab";
import Tabs from "../tabs/Tabs";
import { Endpoints } from "../../constants/constants.js";

export default {
  name: "SearchPane",
  components: {
    Autocomplete,
    Carousel,
    Item,
    SubmitButton,
    Tab,
    Tabs,
  },
  props: {
    arrowImg: {
      type: String,
      required: false,
    },
    buttonText: {
      type: String,
      required: false,
    },
    bgImage: {
      type: String,
      required: false,
    },
    carouselImg: {
      type: String,
      required: false,
    },
    carouselContent: {
      type: Object,
      required: true,
    },
    countryParam: {
      type: String,
      required: true,
    },
    header: {
      type: String,
      required: false,
    },
    inputHeadings: {
      type: Object,
      required: true,
    },
    placeholder: {
      type: Object,
      required: false,
    },
    searchUrl: {
      type: String,
      required: true,
    },
    tabs: {
      type: Object,
      required: false,
    },
    taxaParam: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      autocompleteOpen: false,
      searchIds: {
        country: "",
        taxa: "",
      },
      searchStrings: {
        country: "",
        taxa: "",
      },
      facility: {
        id: "",
      },
      searchResponse: [],
      endpoints: Endpoints,
      itemsForCarousel: [],
      carouselKey: 0,
    }
  },
  computed: {
    baseUrl() {
      return this.searchUrl.replace(".json", "")
    },
    callWithParamsUrl() {
      return `${this.searchUrl}?${this.countryParam}=${this.searchIds.country}&${this.taxaParam}=${this.searchIds.taxa}`
    },
  },
  created() {
    axios
      .get("/forums/categories.json")
      .then((response) => {
        this.itemsForCarousel = response.data
        this.forceRerender()
      })
      .catch((error) => {
        console.log(error.response)
      });
  },
  methods: {
    taxaInputSelect(value) {
      value = value.result;
      this.searchIds.taxa = value.id || "";
      this.searchStrings.taxa = value.full_name;
    },
    countryInputSelect(value) {
      value = value.result;
      this.searchIds.country = value.id || "";
      this.searchStrings.country = value.name;
    },
    forceRerender() {
      this.carouselKey += 1
    },
    disableSubmit(value) {
      this.autocompleteOpen = value
    },
    submit() {
      // use another .then to make sure the async request has finished before redirect
      axios
        .get(this.callWithParamsUrl)
        .then((response) => {
          this.searchResponse = response.data;
          this.$store.dispatch("updateResults", this.searchResponse);
          this.$store.dispatch("updateValues", this.searchStrings);
        })
        .then(() => Turbolinks.visit(`/facilities`))
        .catch((error) => {
          console.log(error.response);
        })
    },
    autosubmit(value) {
      if (value.input.id) {
        Turbolinks.visit(`facilities/${value.input.id}`);
      }
    },
  },
}
</script>
