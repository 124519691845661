<template>
  <div class="px-10 py-14">
    <div class="text-center">
      <h5>Comment on <span class="italic">{{ applicationForumThreadName }}</span> application</h5>
    </div>
    <textarea
      class="h-60 my-5 p-3 bg-white border rounded block w-full"
      v-model="text"
      name="body"
      placeholder="Enter your comment"
    />
    <div class="grid justify-items-end">
      <button class="cursor-pointer w-24 h-10 bg-blue bottom-10 text-white" @click="submit">
        Submit
      </button>
    </div>
  </div>
</template>

<script>

import axios from "axios";
import { setAxiosHeaders } from "../../../helpers/axios";
import Turbolinks from "turbolinks";

export default {
  name: "ApplicationPostForm",

  props: {
    id: {
      required: true,
      type: String,
    },
    applicationForumThreadName: {
      required: false,
      type: String,
    },
    applicationPostPath: {
      type: String,
      required: false,
    },
  },
  data() {
    return { text: "" }
  },
  mounted() {
    setAxiosHeaders(axios);
  },
  methods: {
    submit() {
      axios.post(this.applicationPostPath, {
        application_post: {
          body: this.text,
        }
      })
      .then((response) => {
        this.$root.$emit("close:modal", `modal-${this.id}`);
        Turbolinks.visit(window.location.toString())
      })
      .catch(function (error) {
        console.log(error);
      });
    },
  },
};
</script>