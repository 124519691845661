<template>
  <div>
    <form class="flex flex-col">
      <FormSection
        v-for="(section, tabIndex) in formData.sections"
        :key="tabIndex"
        :title="section.title"
        :speciesName="section.species_name"
        :subtitle="section.subtitle"
        :canBeCloned="section.canBeCloned"
        :isHidden="section.isHidden"
        :tabTitle="section.tabTitle"
        :tabs="section.tabs"
        class="mb-8"
      >
        <template slot-scope="slotProps">
          <div
            v-for="(field, index) in slotProps.tab || section.fields"
            :key="index"
            :class="field.input.type === 'hidden' ? 'hidden' : 'mb-8'"
          >
            <FormLabel
              v-if="field.label"
              :fieldName="field.input.name"
              :hideLabel="hideLabel"
              :required="isRequired(field.validation)"
              :title="field.label.title"
              :description="field.label.description"
            />

            <component
              v-bind:is="getFieldComponent(field.input.type)"
              v-on:update="updateInputData"
              :config="field.input.config"
              :id="field.input.id"
              :name="field.input.name"
              :options="field.input.options"
              :value="field.input.value"
              :isDependent="field.input.isDependent"
              :type="field.input.type"
              :placeholder="field.input.placeholder"
              :parentField="[field.input.parentField, parent]"
              :isParentField="field.input.isParentField"
              :inTab="section.canBeCloned"
              :tabId="tabKey"
              :blobs="field.input.postData"
            />
            <ToggleDetails
              v-if="field.input.details"
              :showDetails="toggleDetails"
              :label="field.input.details.label"
              :type="field.input.details.type"
            />
          </div>
          <div class="flex" v-if="section.remoteForms">
            <div v-for="(form, i) in section.remoteForms" :key="`form=${i}`">
              <ModalTrigger
                class="inline-block mr-6 underline text-blue"
                v-if="facilityId || form.id == 'add-facility'"
                @open="getModalContent(form.id)"
                :id="form.id"
              >
                {{ form.formTriggerText }}
              </ModalTrigger>
              <ModalTarget
                :id="form.id"
                class="fixed inset-0 bg-grey bg-opacity-60 overflow-y-auto"
              >
                <FormWrapper
                  :formData="modalData"
                  :key="modalDataToString"
                  :id="form.id"
                  class="px-12 pt-8 pb-12 bg-white rounded"
                />
              </ModalTarget>
            </div>
          </div>
        </template>
      </FormSection>
      <ErrorBanner :key="errorKey" :errors="errorBag" />
      <input
        class="cursor-pointer py-3 px-9 self-end text-lg bg-blue text-white hover:bg-green"
        type="submit"
        :value="formData.submit ? formData.submit.value : ''"
        @click.prevent="submitForm"
      >
    </form>
  </div>
</template>
<script>
import axios from "axios"
import { getFieldConfig } from "../../helpers/fields"
import { setAxiosHeaders } from "../../helpers/axios"

import Autocomplete from "../autocomplete/Autocomplete"
import Checkboxes from "../formElements/Checkboxes"
import DateInput from "../formElements/DateInput"
import DropdownSelect from "../dropdown/DropdownSelect"
import ErrorBanner from "../banner/ErrorBanner"
import FieldInput from "../formElements/FieldInput"
import FileUpload from "../formElements/FileUpload"
import FormSection from "./FormSection"
import FormLabel from "../formElements/FormLabel"
import HiddenField from "../formElements/HiddenField"
import ModalTarget from "../modal/ModalTarget"
import ModalTrigger from "../modal/ModalTrigger"
import Textarea from "../formElements/Textarea"
import ToggleDetails from "../formElements/ToggleDetails"
import Turbolinks from "turbolinks"

export default {
  name: "FormWrapper",
  components: {
    Autocomplete,
    Checkboxes,
    DateInput,
    DropdownSelect,
    ErrorBanner,
    FieldInput,
    FileUpload,
    FormLabel,
    FormSection,
    HiddenField,
    ModalTarget,
    ModalTrigger,
    Textarea,
    ToggleDetails,
  },
  props: {
    id: {
      type: String,
    },
    formData: {
      type: Object,
    },
  },

  data() {
    return {
      inputData: {},
      tabsArray: [{}],
      counter: 0,
      tabKey: 0,
      tabs: 0,
      modalData: {},
      parent: "",
      toggleDetails: "",
      facilityId: "",
      errorBag: [],
      errorKey: Math.random(),
      hideLabel: ''
    }
  },

  computed: {
    detailsNotEmpty() {
      return this.toggleDetails.length > 0
    },
    modalDataToString() {
      return JSON.stringify(this.modalData)
    },
  },

  mounted() {
    setAxiosHeaders(axios)
  },

  methods: {
    appendDestroy(tabs) {
      tabs.forEach((tab) => {
        this.tabsArray[tab]["_destroy"] = true
      })
    },

    getModalContent(modalId) {
      let url = ""
      if (modalId === "add-facility") {
        url = "/facilities/new.json"
      } else {
        url = `/facilities/${this.facilityId}/edit.json`
      }
      axios
        .get(url)
        .then((response) => {
          this.modalData = JSON.parse(JSON.stringify(response.data))
        })
        .catch(function (error) {console.log(error)})
    },

    getFieldComponent(type) {
      switch (type) {
        case "autocomplete":
          return "Autocomplete"
        case "dropdown":
          return "DropdownSelect"
        case "file":
          return "FileUpload"
        case "number":
          return "FieldInput"
        case "text":
          return "FieldInput"
        case "date":
          return "DateInput"
        case "checkbox":
          return "Checkboxes"
        case "textarea":
          return "Textarea"
        case "hidden":
          return "HiddenField"
        default:
          return false
      }
    },
    ///method for getting a parent id
    filterOptionsBy(field) {
      if (field.isParentField) {
        this.parent = field.input.id
      }
    },

    getInputConfig(type) {
      return getFieldConfig(type)
    },

    hideChildLabels(field) {
      field.noOptions ? this.hideLabel = field.name : this.hideLabel = ''
    },

    isRequired(string) {
      return string ? string.toLowerCase().includes("required") : false
    },

    updateInputData(field) {
      this.filterOptionsBy(field)
      this.hideChildLabels(field)
      if (field.result) {
        const addressArray = [
          field.result.address_line_1,
          field.result.address_line_2,
          field.result.region,
          field.result.zip_code,
          field.result.country,
        ].filter((item) => item !== undefined)
        this.updateToggle(addressArray)
      }

      if (field.name == "facility_id") {
        this.facilityId = field.input
      }

      this.populateValues(field)
    },

    extractParamValues(field, objName) {
      // this case is to support posting nested params which are passed in props hash to name: "parent.child"
      if (field.name.includes(".")) {
        objName[field.name.split(".")[0]][field.name.split(".")[1]] =
          field.input.id || field.input
      } else {
        if (field.name === 'id' && field.input === null) {
          field.input = NaN
        }
        objName[field.name] =
          field.input.postData || field.input.id || field.input
      }
      this.$root.$on("tab:destroy", this.appendDestroy)
    },

    async populateValues(field) {
      await this.$root.$on("tab:ids", this.getTabs)
      if (field.inTab) {
        //if a field exists in this the tab key, increase key number to build initial tabs array
        if (this.tabsArray[this.counter][field.name]) {
          this.counter = this.counter + 1
          // if no tab key and the counter isn't larger than tabs lenght, create the key
          if (!this.tabsArray[this.counter] && this.counter < this.tabs) {
            this.tabsArray[this.counter] = {}
            // any other time, get the key from emitted current tab to override values
          } else {
            this.counter = this.tabKey
          }
        }
        this.tabsArray[this.counter][field.name] = Object.assign(
          {},
          this.tabsArray[this.counter][field.name],
          field
        )
        this.extractParamValues(field, this.tabsArray[this.counter])
      } else {
        this.extractParamValues(field, this.inputData)
      }
    },

    //method to recognise the tab and where to push data
    getTabs(length, tab) {
      this.tabKey = tab
      this.tabs = length
      //create an obj in tabsArray if new tab
      if (!this.tabsArray[tab]) {
        this.tabsArray[tab] = {}
      }
    },

    submitForm() {
      const params = {}

      params[this.formData.base_key] = {
        ...this.inputData,
        species: this.tabsArray,
        annual_stocks_attributes: this.tabsArray,
      }

      const options = {
        method: this.formData.method,
        data: params,
      }

      axios(this.formData.action, options)
        .then((response) => {
          //some forms will be within a modal
          if (response.data.redirect_path) {
            Turbolinks.visit(response.data.redirect_path)
          }
          this.$root.$emit("close:modal", `modal-${this.id}`)
        })
        .catch((error) => {
          console.log(error)
          if (error.response.data) {
            this.errorKey++
            if (error.response.data.errors[0].message) {
              this.errorBag = error.response.data.errors[0].message
            } else {
              this.errorBag = error.response.data.errors
            }
          }
        })
    },

    updateToggle(value) {
      this.toggleDetails = value
    },
  },
}
</script>
