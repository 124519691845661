/**
 * Sorts numbers and strings by property, asc/desc
 * @param property
 * @param order can be 1(for asc) or -1(for desc)
 */

export const dynamicSort = (property, order) => {
  let sortOrder = order;
  if (property[0] === "-") {
    sortOrder = -order;
    property = property.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers,
     */
    let result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder
  }
}
