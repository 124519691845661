<template>
  <button
    :id="triggerId"
    class="modal__trigger"
    @click.prevent="openModal"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'ModalTrigger',

  props: {
    id: {
      type: String,
      required: true
    }
  },

  computed: {
    triggerId () {
      return 'modal-' + this.id
    }
  },

  methods: {
    openModal () {
      this.$root.$emit(this.triggerId)
      this.$emit('open', this.triggerId)
    }
  }
}
</script>
