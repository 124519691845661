<template>
  <header class="flex flex-col">
    <div
      class="flex-1 w-full bg-cover bg-center bg-no-repeat"
      :style="{ backgroundImage: `url('${heroImg}')` }"
    />
    <div :class="backgroundColorClass" class="flex-1">
      <h4 class="p-site" v-if="localeHero.subtitle">{{ localeHero.subtitle }}</h4>
    </div>
  </header>
</template>

<script>
export default {
  name: "SideHero",
  props: {
    localeHero: {
      required: true,
      type: Object,
    },
    heroImg: {
      type: String,
    },
    backgroundColorClass: {
      required: false,
      type: String,
    },
  },
};
</script>
