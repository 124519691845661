<template>
  <div>
    <dl class="mx-site md:mx-96 mb-12" role="presentation">
      <AccordionItem
        v-for="(item, index) in questions"
        :item="item"
        :key="index"
      />
    </dl>
  </div>
</template>

<script>
import AccordionItem from "./AccordionItem.vue";

export default {
  name: "Accordion",

  components: {
    AccordionItem,
  },
  provide() {
    return { accordion: this.accordion };
  },

  props: {
    questions: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      accordion: {
        count: 0,
        active: null,
      },
    };
  },
};
</script>
