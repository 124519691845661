<template>
  <div class="h-full pb-20">
    <div
      :style="{ backgroundImage: `url('${image}')` }"
      class="h-1/2 bg-cover flex justify-center items-center mb-5"
    >
      <h2 class="text-white">{{ localeApplication_start.title }}</h2>
    </div>
    <div class="flex justify-center">
      <p class="w-1/3 my-10">
        {{ localeApplication_start.body }}
      </p>
    </div>
    <div class="flex justify-center">
      <a
        title="captive breeding account"
        :href="'/users/' + userId"
        class="bg-blue ml-4 my-6 py-3 text-xl text-white px-8"
        >{{ localeApplication_start.buttons.left }}</a
      >
      <a
        title="captive breeding facility"
        :href="'/facilities/' + facilityId"
        class="bg-blue ml-4 my-6 py-3 text-xl text-white px-8"
        >{{ localeApplication_start.buttons.right }}</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "ApplicationStart",

  props: {
    localeApplication_start: {
      required: true,
      type: Object,
    },
    image: {
      required: false,
      type: String,
    },
    userId: {
      required: true,
      type: String,
    },
    facilityId: {
      default: 1,
      type: Number,
    },
  },
};
</script>
