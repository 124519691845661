<template>
  <div
    class="w-2/3 flex my-16 justify-between"
  >
    <button
      v-for="(item, itemIndex) in text"
      class="flex justify-between w-full mr-6 py-3 px-6 border rounded-sm hover:shadow-card active:shadow-card"
      :key="itemIndex"
      @click="jump(itemIndex)"
    >
      <h4>
        {{ item }}
      </h4>
      <span class="text-xl text-grey">&#x2193;</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'JumpLinks',

  props: {
    text: {
      type: Array,
      required: true,
    },
  },
  methods: {
    jump(index) {
      this.$emit('go', index);
    },
  },
};
</script>
